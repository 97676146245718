
import { IonButton } from '@ionic/react';
import React from 'react';
import styles from './answer-button.module.css';

interface MyButtonWithBorderProps extends React.PropsWithChildren<{
  className?: string;
  containerClassName?: string;
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
}> {}

const AnswerButton = (props: MyButtonWithBorderProps) => {

  return (
    <IonButton
      fill='clear'
      className={`${styles.answerButton} ${props.containerClassName}`}
      onClick={props?.onClick}>
          <div className={props?.className}>
            {props.children}
          </div>
    </IonButton>
  );
};
// style={{'--background': 'magenta'}}
export default AnswerButton;
