import { IonButton, IonButtons, IonContent, IonHeader, IonImg, IonPage, IonToolbar } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
// import 'swiper/swiper.min.css';
import 'swiper/css';
import MyButtonWithBorder from '../../components/my-button-with-border/my-button-with-border';
import Slider from '../../components/slider/slider';
import { Routes } from '../../routes';
import styles from './tutorial-page.module.css';

const TutorialPage = () => {

    const { t } = useTranslation();
    const [slideIndex, setSlideIndex] = useState(0)

    const firstView = (
            <div className={styles.viewContainer}>
                <p className={styles.viewDescription}>{t('tutorial.view1')}</p>
                <IonImg src='assets/tutorial/sign.svg' alt='Sign' className={styles.sliderViewImg}></IonImg>
            </div>)
    const secondView = (
            <div className={styles.viewContainer}>
                <p className={styles.viewDescription}>{t('tutorial.view2')}</p>
                <IonImg src='assets/tutorial/columns.svg' alt='Columns' className={styles.sliderViewImg}></IonImg>
            </div>)
    const thirdView = (
            <div className={styles.viewContainer}>
                <p className={styles.viewDescription}>{t('tutorial.view3')}</p>
                <IonImg src='assets/tutorial/bottle.svg' alt='Bottle' className={styles.sliderViewImg}></IonImg>
                <MyButtonWithBorder size='large' color='secondary' routerLink={Routes.ACCESS} className={`${styles.beginButton} ion-text-uppercase`}>{t('ui.start')}</MyButtonWithBorder>
            </div>)

return (
    <IonPage>
        <IonHeader className='ion-no-border'>
        <IonToolbar color='primary'>
          <IonButtons className={styles.navButtonCtnLeft} style={{ visibility: slideIndex > 0 ? 'visible' : 'hidden'}} slot='start'>
              <IonButton color='tertiary' className={`${styles.backBtn} back secondary-font ion-text-uppercase`}>{t('ui.back')}</IonButton>
          </IonButtons>
          <IonButtons className={styles.navButtonCtnRight} style={{ visibility: slideIndex < 2 ? 'visible' : 'hidden'}} slot='end'>
              <IonButton color='tertiary' className={`${styles.nextBtn} next secondary-font ion-text-uppercase`}>{t('ui.next')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
        <IonContent>
            <Slider onSlideChange={setSlideIndex} defaultValue={slideIndex} navigation={{nextEl: '.next', prevEl: '.back'}} className={styles.sliderContainer} slides={[firstView, secondView, thirdView]}></Slider>
        </IonContent>
    </IonPage>
  );
};
export default TutorialPage;