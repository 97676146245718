import { IonLabel } from '@ionic/react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import styles from './my-autocomplete.module.css';

export interface MyAutocompleteItem {
  id: string;
  name: string;
}

export interface MyAutocompleteProps {
  items: MyAutocompleteItem[];
  placeholder?: string;
  className?: string;
  label: string;
  onSelect?: (event: any) => void;
  onClear?: () => void;
  onSearch?: (event: any) => void;
  // onChange?: (event: any) => void;
  // onFocus?: (event: any) => void;
}

const MyAutocomplete = (props: MyAutocompleteProps) => {
  return (
    <div className={`${styles.inputFieldContainer} ${props.className} ion-margin-top`}>
      <IonLabel className={`${styles.inputLabel} ion-text-start secondary-font`} position='fixed'><span className={styles.inputLabelText}>{props.label}</span></IonLabel>
      <ReactSearchAutocomplete showItemsOnFocus={true} onClear={() => { if(props.onClear) props.onClear() }} onSearch={(e) => { if(props.onSearch) props.onSearch(e) }} onSelect={(e) => { if(props.onSelect) props.onSelect(e) }} styling={{
        zIndex: 1,
        backgroundColor: 'var(--input-field-background-color)',
        boxShadow: 'none'}} items={props.items} placeholder={props.placeholder}></ReactSearchAutocomplete>
    </div>
  );
};
export default MyAutocomplete;
