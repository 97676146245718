import { IonicSlides } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { ReactNode } from 'react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

interface SliderProps {
    slides: ReactNode[],
    navigation?: {
        prevEl: string,
        nextEl: string,
    },
    pagination?: {
        bulletClass: string,
        bulletActiveClass: string,
    },
    className?: string,
    defaultValue: number,
    onSlideChange?: (newIndex: number) => void;
}

const Slider = (props: SliderProps) => {
return (
    <Swiper onSlideChange={(e) => {if (props?.onSlideChange) props.onSlideChange(e.activeIndex)}} className={props.className} modules={[Pagination, Navigation, IonicSlides]}
    defaultValue={props.defaultValue}
    navigation={props.navigation ?? false}
    pagination={props.pagination ?? true}>
        {props.slides.map((slide, i) => (<SwiperSlide key={i}>{slide}</SwiperSlide>))}
    </Swiper>
  );
};
export default Slider;