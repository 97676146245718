import { Itinerary } from "../api/itineraries.models";
import { ItineraryToken } from "../api/itineraryTokens.models";
import { WaypointFull } from "../api/waypoints.models";

export enum WaypointPhase {
  INTRO = "intro",
  GAME = "game",
  ANECDOTE = "anecdote",
  RECAP = "recap",
}

export type ItineraryContextPayload = {
  currentItinerary?: Itinerary;
  itineraryToken?: string;
  currentWaypoint?: WaypointFull;
  waypointPhase: WaypointPhase;
  gameIndex: number;
  currentScore: number;
  continue: () => void;
  startItinerary: (itineraryToken: ItineraryToken) => void;
  endItinerary: () => void;
  addScore: (score: number) => void;
};
