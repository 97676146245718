
import { IonButton } from '@ionic/react';
import React from 'react';
import styles from './character-button.module.css';

interface MyButtonWithBorderProps extends React.PropsWithChildren<{
  className?: string;
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  width?: string;
  color?: string;
  disabled?: boolean;
  fill?: "clear" | "outline" | "solid";
  height?: string;
}> {}

const CharacterButton = (props: MyButtonWithBorderProps) => {

  return (
    <IonButton
      disabled={props?.disabled}
      fill={props?.fill ?? 'clear'}
      color={props?.color}
      style={{
        width: props.width,
        height: props.height,
      }}
      className={styles.characterButton}
      onClick={props?.onClick}>
          <div className={props?.className}>
            {props.children}
          </div>
    </IonButton>
  );
};
// style={{'--background': 'magenta'}}
export default CharacterButton;
