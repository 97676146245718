import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { object, string } from "yup";
import InputFieldController, {
  InputFieldControllerProps,
} from "../../../components/input-field-controller/input-field-controller";
import MyButtonWithBorder from "../../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../../components/my-modal/my-modal";
import { Routes } from "../../../routes";
import { post } from "../../../services/http-service";
import { ENDPOINT_REQUEST_NEW_PASSWORD } from "../../../variables";
import styles from "./recover-password-page.module.css";

const RecoverPasswordPage = () => {
  const resolverSchema = object({
    email: string().required().email(),
  }).required();

  // hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resolverSchema) });
  const { t } = useTranslation();
  const [recoverRequestStatus, setRecoverRequestStatus] = useState("not_sent");

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== Routes.RECOVER_PASSWORD) return;
    setRecoverRequestStatus("not_sent");
  }, [location]);

  const formFields: InputFieldControllerProps[] = [
    {
      control,
      label: "E-MAIL",
      name: "email",
      type: "text",
      placeholder: "email",
    },
  ];

  const submit = async (e: any) => {
    try {
      setRecoverRequestStatus("pending");
      const res = await post<any>({
        endpoint: ENDPOINT_REQUEST_NEW_PASSWORD,
        data: { email: e.email },
      });

      if (!res.error) {
        setRecoverRequestStatus("success");
      } else {
        setRecoverRequestStatus("failure");
      }
    } catch (err) {
      setRecoverRequestStatus("failure");
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="end">
            <IonButton
              routerLink={Routes.ACCESS}
              color="tertiary"
              className={`${styles.registerBtn} secondary-font ion-text-uppercase`}
            >
              {t("access.login")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={`${styles.signupFormContainer} ion-padding`}>
          <IonImg
            src="assets/logo.svg"
            alt="Zonzo logo"
            className={styles.logo}
          ></IonImg>
          <form className={styles.signupForm} onSubmit={handleSubmit(submit)}>
            {formFields.map((field) => (
              <IonItem
                className={styles.inputItem}
                lines="none"
                key={field.name}
              >
                {
                  <InputFieldController
                    {...field}
                    className={
                      !!errors[field.name]?.message
                        ? styles.inputFieldError
                        : styles.inputField
                    }
                  />
                }
              </IonItem>
            ))}
            <MyButtonWithBorder
              disabled={recoverRequestStatus !== "not_sent"}
              size="large"
              color="secondary"
              type="submit"
              className={`${styles.enterBtn} ion-margin-top ion-text-uppercase`}
            >
              {t("access.recover")}
            </MyButtonWithBorder>
            {/* <MyButtonWithBorder size='large' color='secondary' routerLink={Routes.WAYPOINT_INTRO}>EXAMPLE ITINERARY</MyButtonWithBorder> */}
          </form>
        </div>
      </IonContent>
      <MyModal
        containerClassName={styles.recoverEndModal}
        isOpen={recoverRequestStatus === "success"}
      >
        {
          <>
            <p>{t("access.recoverPasswordSuccess")}</p>
            <MyButtonWithBorder
              routerLink={Routes.ACCESS}
              size="large"
              color="secondary"
              className="ion-margin-top ion-text-uppercase"
            >
              {t("access.login")}
            </MyButtonWithBorder>
          </>
        }
      </MyModal>
      <MyModal
        containerClassName={styles.recoverEndModal}
        isOpen={recoverRequestStatus === "failure"}
      >
        {
          <>
            <p>{t("access.recoverPasswordFailure")}</p>
            <MyButtonWithBorder
              onClick={() => setRecoverRequestStatus("not_sent")}
              size="large"
              color="secondary"
              className="ion-margin-top ion-text-uppercase"
            >
              {t("access.login")}
            </MyButtonWithBorder>
          </>
        }
      </MyModal>
    </IonPage>
  );
};

export default RecoverPasswordPage;
