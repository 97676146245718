import {
  PaymentElement, useElements, useStripe
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import styles from './payment-form.module.css';

interface PaymentProps {
    clientSecret: string,
    onSuccess: () => void,
    onError: (error: string) => void,
}

function PaymentForm(props: PaymentProps) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSucceded, setIsSucceded] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!props.clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(props.clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const paymentResult = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (paymentResult.error) {
      // TODO: handle error case
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (paymentResult.error.type === "card_error" || paymentResult.error.type === "validation_error") {
        props.onError(paymentResult.error.message || 'Unexpected error');
      } else {
        props.onError('Unexpected error');
      }
    } else if (paymentResult.paymentIntent?.status === "succeeded") {
      // TODO: handle success case
      setIsSucceded(true);
      props.onSuccess();
    }

    setIsLoading(false);
    setIsCompleted(true);
  };

  return (
    <form className={styles.paymentForm} id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement className={styles.paymentElement} id="payment-element" />
      <MyButtonWithBorder className={styles.payButton} color="secondary" disabled={isLoading || !stripe || !elements} id="submit">
        {t('payment.payNow')}
      </MyButtonWithBorder>
      {/* Show any error or success messages */}
      {(isCompleted && !isSucceded && message) && <div id="payment-message">{message}</div>}
    </form>
  );
}

export default PaymentForm;