import {
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonList,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import "@ionic/react/css/ionic-swiper.css";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import "swiper/css";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import Navbar from "../../components/navbar/navbar";
import { LocalizationContext } from "../../contexts/localization.context";
import { SelectionContext } from "../../contexts/selection.context";
import { UserContext } from "../../contexts/user.context";
import {
  GetItinerariesByCityOutput,
  Itinerary,
} from "../../models/api/itineraries.models";
import { Routes } from "../../routes";
import { get } from "../../services/http-service";
import {
  ENDPOINT_GET_ITINERARIES_FILTERED,
  TAG_IMAGES_FOLDER,
} from "../../variables";
import styles from "./itinerary-selection.module.css";

const ItinerarySelectionPage = () => {
  const userContext = useContext(UserContext);
  const selectionContext = useContext(SelectionContext);
  const localizationContext = useContext(LocalizationContext);
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [itineraries, setItineraries] = useState<Itinerary[]>([]);
  const [isPending, setPending] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname !== Routes.ITINERARY_SELECTION) return;

    fetchItineraries();
  }, [selectionContext]);

  function itineraryButtonClick(itinerary: Itinerary) {
    selectionContext.selectItinerary(itinerary);
    history.push(Routes.SELECTED_ITINERARY_INTRO);
  }

  const itineraryItems = itineraries.map((itinerary) => (
    <IonItem
      className={styles.itinerariesListItem}
      key={itinerary.id}
      lines="none"
    >
      <MyButtonWithBorder
        className={`${styles.itinerariesListItemButton} ion-text-uppercase`}
        onClick={() => itineraryButtonClick(itinerary)}
        size="default"
        color={itinerary.cost === 0 ? "tertiary" : "secondary"}
      >
        {itinerary.locales[0].name}
      </MyButtonWithBorder>
    </IonItem>
  ));

  async function fetchItineraries() {
    setFetchError(false);
    setPending(true);
    const filters = {
      city: selectionContext.selectedCity,
      tagId: selectionContext.selectedTag?.id,
      published: true,
    };
    try {
      const res = await get<GetItinerariesByCityOutput>({
        endpoint: `${ENDPOINT_GET_ITINERARIES_FILTERED}/${encodeURIComponent(
          JSON.stringify(filters)
        )}?locale=${
          localizationContext.locale ?? localizationContext.defaultLocale
        }`,
      });
      setItineraries(res.filter((itinerary) => itinerary.locales.length > 0));
    } catch (err) {
      console.log(err);
      setFetchError(true);
    }
    setPending(false);
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar nextButtonDisabled={true} toolbarColor="primary" />
      </IonHeader>
      <IonContent>
        <div className={styles.viewContainer}>
          {isPending ? (
            <IonSpinner />
          ) : fetchError ? (
            <>
              <p>{t("itinerarySelection.fetchError")}</p>
              <MyButtonWithBorder
                onClick={() => fetchItineraries()}
                size="default"
                color="secondary"
              >
                {t("itinerarySelection.fetch")}
              </MyButtonWithBorder>
            </>
          ) : !!selectionContext.selectedTag ? (
            <>
              <IonImg
                className={styles.tagImage}
                src={`${TAG_IMAGES_FOLDER}/${selectionContext.selectedTag.logoName}`}
                alt={selectionContext.selectedTag.locales[0].name}
              ></IonImg>
              <p className={`${styles.upperDescription} ion-text-uppercase`}>
                {t("itinerarySelection.chooseItinerary")}:
              </p>
              <IonList className={styles.itinerariesList}>
                {itineraryItems}
              </IonList>
              {/* <Virtuoso style={{height: '100%', width: '100%'}} totalCount={itineraryItems.length} itemContent={(index) => {
                            return (
                                <div key={index}>
                                {
                                    itineraryItems[index]
                                }
                                </div>
                            )
                        }}>
                        </Virtuoso> */}
            </>
          ) : (
            <p>{t("tagSelection.noTagSelected")}</p>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
export default ItinerarySelectionPage;
