// import { GoogleAnalytics } from "@awesome-cordova-plugins/google-analytics";
import {
  createGesture,
  Gesture,
  IonApp,
  isPlatform,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { useEffect, useState } from "react";
import "./app.css";
import "./i18n/config";
import Store from "./store";
import StoreContent from "./storeContent";
/* Theme variables */
import "./theme/variables.css";

setupIonicReact();

const App: React.FC = () => {
  const [init, setInit] = useState(false);
  const [backGesture, setBackGesture] = useState<Gesture>();

  useEffect(() => {
    async function initGoogleAnalytics() {
      try {
        if (isPlatform("android")) {
          // await GoogleAnalytics.startTrackerWithId("GTM-TT378ZH");
          console.log("Google analytics is ready now");
          // GoogleAnalytics.trackView("Android tracker");
        } else {
          // await GoogleAnalytics.startTrackerWithId("GTM-MNTGTV6");
          console.log("Google analytics is ready now");
          // GoogleAnalytics.trackView("IOS tracker");
        }
      } catch (err) {
        console.log(err);
        console.log("Error while starting Google analytics");
      }
    }


    if (!init) {
      setInit(true);

      const rootEl = document?.getElementById("root");
      if (rootEl) {
        const tmpGesture = createGesture({
          el: rootEl,
          threshold: 10,
          gestureName: "my-back-gesture",
          direction: "x",
          gesturePriority: 40.5, // priority of swipe to go back is 40
          onMove: (ev) => {},
        });
        setBackGesture(tmpGesture);

        tmpGesture.enable(true);
      }
    }

    return function cleanup() {
      if (backGesture) {
        backGesture.enable(false);
        backGesture.destroy();
      }
    };
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <Store>
          <StoreContent />
        </Store>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
