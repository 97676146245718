import {
  IonButton,
  IonButtons,
  IonCard,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { arrowBack, checkmark } from "ionicons/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MyButtonWithBorder from "../my-button-with-border/my-button-with-border";
import styles from "./my-nfts.module.css";
interface Props {
  selectedNftIndex: number | null;
  userNfts: {
    amount: number;
    metadata: {
      title: string;
      description: string;
      cover: string;
      creator: string;
    };
    metadata_hash: string;
    nft_owner: string;
    transfereable: boolean;
  }[];
  toggleModal?: () => void;
}

const UserNft = ({ selectedNftIndex, userNfts, toggleModal }: Props) => {
  // DISABLED FROM UI
  const [isDisabled, setIsDisabled] = useState(true);

  const { t } = useTranslation();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessComplete, setIsProcessComplete] = useState(false);

  const selectedNFT =
    selectedNftIndex !== null ? userNfts[selectedNftIndex] : null;

  const toggleConfirmModal = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsProcessComplete(true);
    }, 2000);
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton expand="block" onClick={toggleModal}>
              <IonIcon icon={arrowBack} size="small"></IonIcon>
            </IonButton>
          </IonButtons>
          {selectedNFT && selectedNftIndex !== null && (
            <IonTitle>NFT #{selectedNftIndex + 1}</IonTitle>
          )}
        </IonToolbar>
      </IonHeader>
      {selectedNFT ? (
        <>
          <div className={`${styles.nftLabel}  ${styles.marginTop10}`}>
            <p style={{ color: "black" }}> {selectedNFT.metadata.title}</p>
          </div>
          <div className={`${styles.wrapperSingleNft}`}>
            <div
              className={`${styles.nftBox}`}
              style={{ backgroundImage: `url(${selectedNFT.metadata.cover})` }}
            ></div>
            {!isDisabled && (
              <div className={`${styles.btnWrapper}`}>
                <IonButton
                  onClick={() => setShowModalConfirm(true)}
                  className={`${styles.btnBordered}`}
                  style={{ marginRight: "10px" }}
                >
                  BURN
                </IonButton>
                <IonButton className={`${styles.btnBordered}`}>
                  CHECK BURNED
                </IonButton>
              </div>
            )}

            <div
              className={`${styles.descriptionContainer} ${
                isPlatform("android") ? styles.descriptionContainerAndroid : ""
              } ${styles.marginTop20}`}
            >
              <IonLabel
                style={{
                  fontSize: "12px",
                  textTransform: "uppercase",
                  color: "black",
                }}
              >
                {t("wallet.nftDescription")}:
              </IonLabel>
              <div className={`${styles.descriptionContent}`}>
                <p style={{ color: "black" }} className={`${styles.noMargins}`}>
                  {selectedNFT.metadata.description}
                </p>
              </div>
            </div>
          </div>

          {/* CONFIRM MODAL BURN NFT */}
          <IonModal
            className={`${styles.confirmModal}`}
            isOpen={showModalConfirm}
          >
            <div className="wrapper">
              <IonCard className={`${styles.confirmModalContainer}`}>
                {isLoading && (
                  <div>
                    <IonSpinner></IonSpinner>
                    <p>Loading, please wait...</p>
                  </div>
                )}

                {!isLoading && !isProcessComplete && (
                  <div>
                    <IonText>Sei sicuro di voler bruciare questo NFT?</IonText>
                    <div style={{ marginTop: "20px" }}>
                      <MyButtonWithBorder
                        className={styles.transferModalBtn}
                        color="primary"
                        onClick={() => toggleConfirmModal()}
                      >
                        CONFERMA
                      </MyButtonWithBorder>
                      <MyButtonWithBorder
                        className={styles.transferModalBtn}
                        color="danger"
                        onClick={() => setShowModalConfirm(false)}
                      >
                        ANNULLA
                      </MyButtonWithBorder>
                    </div>
                  </div>
                )}
                {!isLoading && isProcessComplete && (
                  <div>
                    <IonIcon
                      color="dark"
                      size="large"
                      icon={checkmark}
                    ></IonIcon>
                    <br></br>
                    <IonText color="dark">
                      <h4>Process completed!</h4>
                    </IonText>
                    <p>You have succesfully burned your NFT.</p>
                    <IonButton
                      color="primary"
                      style={{ width: "100px" }}
                      onClick={() => {
                        setShowModalConfirm(false);
                        setIsProcessComplete(false);
                      }}
                      className={`${styles.btnBordered}`}
                    >
                      OK
                    </IonButton>
                  </div>
                )}
              </IonCard>
            </div>
          </IonModal>
        </>
      ) : (
        <p>Seleziona un prodotto</p>
      )}
    </>
  );
};

export default UserNft;
