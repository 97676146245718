import React from 'react';
import Box from '../box/box';
import styles from './boxed-title.module.css';

interface BoxedTitleProps extends React.PropsWithChildren<{
  className?: string;
}> {}

const BoxedTitle = (props: BoxedTitleProps) => {

  return (
    <Box
      className={`${styles.boxedTitle} ${props?.className} ion-text-uppercase`}>
      {props.children}
    </Box>
  );
};
// style={{'--background': 'magenta'}}
export default BoxedTitle;
