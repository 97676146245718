import { IonButton } from "@ionic/react";
import React from "react";
import { Routes } from "../../routes";
import styles from "./my-button-with-border.module.css";

interface MyButtonWithBorderProps
  extends React.PropsWithChildren<{
    id?: string;
    className?: string;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    color?: string;
    routerLink?: Routes | string;
    fill?: "solid" | "clear" | "outline";
    size?: "small" | "default" | "large";
    onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  }> {}

const MyButtonWithBorder = (props: MyButtonWithBorderProps) => {
  return (
    <IonButton
      routerLink={props.routerLink}
      type={props?.type}
      id={props?.id}
      disabled={props?.disabled}
      fill={props.fill ?? "solid"}
      className={`${styles.myButton} ${props?.className}`}
      size={props.size}
      color={props.color}
      onClick={props?.onClick}
    >
      {props.children}
    </IonButton>
  );
};
// style={{'--background': 'magenta'}}
export default MyButtonWithBorder;
