import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { v4 } from "uuid";
import MyButtonWithBorder from "../../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../../components/my-modal/my-modal";
import Navbar from "../../../components/navbar/navbar";
import { ItineraryContext } from "../../../contexts/itinerary.context";
import { Routes } from "../../../routes";
import { TAG_IMAGES_FOLDER } from "../../../variables";
import styles from "./waypoint-anecdote.module.css";

const WaypointAnecdotePage = () => {
  const { t } = useTranslation();
  const itineraryContext = useContext(ItineraryContext);
  const [anecdote, setAnecdote] = useState<string | undefined>("");
  const [anecdoteHint, setAnecdoteHint] = useState<string | undefined>("");
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const [myKey, setMyKey] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_ANECDOTE)) return;
    // setMyIndex(itineraryContext.gameIndex);
    setMyKey(v4());
    setAnecdote(itineraryContext.currentWaypoint?.locales?.[0]?.anecdote);
    setAnecdoteHint(
      itineraryContext.currentWaypoint?.locales?.[0]?.anecdoteHint
    );
  }, [location]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar nextButtonDisabled={true} backButtonDisabled={true} />
      </IonHeader>
      <IonContent color="secondary">
        <div className={styles.waypointAnecdoteContainer}>
          <div>
            <LazyLoadImage
              className={styles.tagImage}
              src={`${TAG_IMAGES_FOLDER}/${itineraryContext.currentItinerary?.tags[0].logoName}`}
              alt="Itinerary tag"
              placeholder={<IonSpinner />}
            ></LazyLoadImage>
          </div>
          <LazyLoadImage
            className={styles.balloonImage}
            src="assets/itinerary/balloon.svg"
            alt="Balloon"
            placeholder={<IonSpinner />}
          ></LazyLoadImage>
          <p
            key={myKey}
            // key={`${itineraryContext.currentItinerary?.id}-${itineraryContext.currentWaypoint?.id}`}
            className={`zonzo-scrollbar-primary ${styles.anecdoteText} ${
              isPlatform("android") ? styles.anecdoteTextAndroid : ""
            } zonzo-scrollbar ion-text-start`}
          >
            {anecdote}
          </p>
          <IonButton
            onClick={() => setReadMoreOpen(true)}
            className={`${styles.readMoreButton} ion-text-uppercase`}
            fill="clear"
          >
            {t("ui.readMore")}
          </IonButton>
          <MyButtonWithBorder
            onClick={() => itineraryContext.continue()}
            size="large"
            color="secondary"
            className={`${styles.continueButton} ion-text-uppercase`}
          >
            {t("ui.continue")}
          </MyButtonWithBorder>
        </div>
        <MyModal
          containerClassName={styles.readMoreModal}
          isOpen={readMoreOpen}
          onCloseClick={() => {
            setReadMoreOpen(false);
          }}
          onBackgroundClick={() => {
            setReadMoreOpen(false);
          }}
        >
          <p className={`${styles.readMoreParagraph} zonzo-scrollbar-primary`}>
            {anecdoteHint}
          </p>
        </MyModal>
      </IonContent>
    </IonPage>
  );
};

export default WaypointAnecdotePage;
