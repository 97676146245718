import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonSpinner,
  IonImg,
} from "@ionic/react";
import "@ionic/react/css/ionic-swiper.css";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import "swiper/css";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import Navbar from "../../components/navbar/navbar";
import { LocalizationContext } from "../../contexts/localization.context";
import { UserStatus } from "../../models/contexts/user.context.model";
import { SelectionContext } from "../../contexts/selection.context";
import { UserContext } from "../../contexts/user.context";
import { ItineraryToken } from "../../models/api/itineraryTokens.models";
import { ItineraryContext } from "../../contexts/itinerary.context";
import { ENDPOINT_GET_ITINERARY_TOKENS_WITH_ITINERARY } from "../../variables";
import {
  GetItinerariesByCityOutput,
  Itinerary,
} from "../../models/api/itineraries.models";
import { Routes } from "../../routes";
import { get } from "../../services/http-service";
import {
  ENDPOINT_GET_ITINERARIES_FILTERED,
  ENDPOINT_GET_TAGS_BY_CITY,
} from "../../variables";
import styles from "./city-itineraries.module.css";
import {
  GetTagsByCityOutput,
  TagWithLocales,
} from "../../models/api/tags.models";

// Add this interface
interface TagsMap {
  [key: number]: TagWithLocales;
}

const CityItinerariesPage = () => {
  const userContext = useContext(UserContext);
  const selectionContext = useContext(SelectionContext);
  const localizationContext = useContext(LocalizationContext);
  const itineraryContext = useContext(ItineraryContext);
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [itineraries, setItineraries] = useState<Itinerary[]>([]);
  const [tags, setTags] = useState<TagsMap>({});
  const [isPending, setPending] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [userItineraryTokens, setUserItineraryTokens] = useState<
    ItineraryToken[]
  >([]);

  useEffect(() => {
    if (location.pathname !== Routes.CITY_ITINERARIES) return;
    fetchItineraries();
    console.log("User status:", userContext.user.status);
    if (userContext.user.status === UserStatus.LOGGED) {
      fetchUserItineraries();
    }
  }, [selectionContext]);

  function itineraryButtonClick(itinerary: Itinerary) {
    selectionContext.selectItinerary(itinerary);
    history.push(Routes.SELECTED_ITINERARY_INTRO);
  }

  function playItinerary(itineraryToken: ItineraryToken) {
    itineraryContext.startItinerary(itineraryToken);
  }

  const itineraryItems = itineraries.map((itinerary) => (
    <div className={styles.itineraryCard} key={itinerary.id}>
      <IonImg
        src={`categories/${itinerary.tags.map((tag) =>
          tags[tag.id].locales[0].name.split(" ").join("-").toLowerCase()
        )}.png`}
        alt="Category"
        className={styles.imgCategory}
      ></IonImg>
      <p className={styles.itineraryTitle}>{itinerary.city}</p>
      <h1>{itinerary.locales[0].name}</h1>
      <p
        dangerouslySetInnerHTML={{ __html: itinerary.locales[0].description }}
      ></p>
      <p className="itineraryCardFooter">
        <IonImg src="assets/coin.png" alt="Price"></IonImg>{" "}
        {itinerary.cost === 0 ? t("ui.free") : itinerary.cost + " EURO"}
      </p>
      <p className="itineraryCardFooter">
        <IonImg src="assets/category.png" alt="Category"></IonImg>
        {itinerary.tags.map((tag) => tags[tag.id].locales[0].name).join(", ")}
      </p>
      <p className="itineraryCardFooter">
        <IonImg src="assets/clock.png" alt="Duration"></IonImg>
        {parseInt(itinerary.duration.split(".")[0]) === 1
          ? itinerary.duration.split(".")[0] + " " + t("ui.hour")
          : itinerary.duration.split(".")[0] + " " + t("ui.hours")}
        {itinerary.duration.split(".")[1] !== undefined &&
          " " + itinerary.duration.split(".")[1] + " " + t("ui.minutes")}
      </p>
      {userItineraryTokens.find(
        (token) => token.itinerary.id === itinerary.id
      ) ? (
        <MyButtonWithBorder
          className={`${styles.itinerariesListItemButton} ion-text-uppercase`}
          onClick={() =>
            playItinerary(
              userItineraryTokens.find(
                (token) => token.itinerary.id === itinerary.id
              )!
            )
          }
          size="default"
          color="primary"
        >
          {t("ui.play")}
        </MyButtonWithBorder>
      ) : (
        userContext.user.status === UserStatus.LOGGED ? (
          <MyButtonWithBorder
            className={`${styles.itinerariesListItemButton} ion-text-uppercase`}
            onClick={() => itineraryButtonClick(itinerary)}
            size="default"
            color={itinerary.cost === 0 ? "tertiary" : "secondary"}
          >
            {itinerary.cost === 0 ? t("ui.unlock") : t("ui.purchase")}
          </MyButtonWithBorder>
        ) : (
          <MyButtonWithBorder
            className={`${styles.itinerariesListItemButton} ion-text-uppercase`}
            onClick={() => history.push(Routes.ACCESS)}
            size="default"
            color="secondary"
          >
            {t("ui.needLogin")}
          </MyButtonWithBorder>
        )
      )}
    </div>
  ));

  async function fetchItineraries() {
    setFetchError(false);
    setPending(true);
    const filters = {
      city: selectionContext.selectedCity,
      published: true,
    };
    try {
      const res = await get<GetItinerariesByCityOutput>({
        endpoint: `${ENDPOINT_GET_ITINERARIES_FILTERED}/${encodeURIComponent(
          JSON.stringify(filters)
        )}?locale=${
          localizationContext.locale ?? localizationContext.defaultLocale
        }`,
      });
      const tagsRes = await get<GetTagsByCityOutput>({
        endpoint: `${ENDPOINT_GET_TAGS_BY_CITY}/${
          selectionContext.selectedCity
        }?locale=${
          localizationContext.locale ?? localizationContext.defaultLocale
        }`,
      });
      for (const tag of tagsRes) {
        console.log("Tag", tag);
        setTags((prevTags) => ({ ...prevTags, [tag.id]: tag }));
      }
      setItineraries(
        res
          .filter((itinerary) => itinerary.locales.length > 0)
          .map((itinerary) => {
            return {
              ...itinerary,
              locales: [
                {
                  ...itinerary.locales[0],
                  description:
                    itinerary.locales[0].description.split(" --- ")[0],
                },
              ],
              duration:
                itinerary.locales[0].description.split(" --- ")[1] ?? "1",
            };
          })
      );
      console.log("Itineraries", itineraries);
    } catch (err) {
      console.log(err);
      setFetchError(true);
    }
    setPending(false);
  }

  async function fetchUserItineraries() {
    try {
      const itineraryTokens = await get<ItineraryToken[]>({
        endpoint: `${ENDPOINT_GET_ITINERARY_TOKENS_WITH_ITINERARY}/${userContext.user.id}`,
        bearerToken: userContext.user.token,
      });
      setUserItineraryTokens(itineraryTokens);
      console.log("User itineraries: ", itineraryTokens);
    } catch (err) {
      console.log("error while fetching user itineraries: ", err);
    }
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar nextButtonDisabled={true} toolbarColor="primary" />
      </IonHeader>
      <IonContent>
        <div className={styles.viewContainer}>
          {isPending ? (
            <IonSpinner />
          ) : fetchError ? (
            <>
              <p>{t("itinerarySelection.fetchError")}</p>
              <MyButtonWithBorder
                onClick={() => fetchItineraries()}
                size="default"
                color="secondary"
              >
                {t("itinerarySelection.fetch")}
              </MyButtonWithBorder>
            </>
          ) : (
            <>
              {/* <p className={`${styles.upperDescription} ion-text-uppercase`}>
                {t("itinerarySelection.chooseItinerary")}:
              </p> */}
              <IonList className={styles.itinerariesList}>
                {itineraryItems}
              </IonList>
              {/* <Virtuoso style={{height: '100%', width: '100%'}} totalCount={itineraryItems.length} itemContent={(index) => {
                            return (
                                <div key={index}>
                                {
                                    itineraryItems[index]
                                }
                                </div>
                            )
                        }}>
                        </Virtuoso> */}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
export default CityItinerariesPage;
