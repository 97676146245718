import { IonBackButton, IonButton, IonButtons, IonToolbar } from "@ionic/react";
import { t } from "i18next";
import React from "react";
import styles from "./navbar.module.css";

interface NavbarProps
  extends React.PropsWithChildren<{
    backButtonDisabled?: boolean;
    nextButtonDisabled?: boolean;
    toolbarColor?: "primary" | "secondary" | "tertiary";
    buttonsColor?: "primary" | "secondary" | "tertiary";
  }> {}

const Navbar = (props: NavbarProps) => {
  return (
    <IonToolbar
      className="backdrop-sensitive"
      color={props.toolbarColor ?? "secondary"}
    >
      {!props.backButtonDisabled && (
        <IonButtons slot="start">
          <IonBackButton
            text={t("ui.back")}
            color={props.buttonsColor ?? "tertiary"}
            className={`${styles.navbarBackBtn} back secondary-font ion-text-uppercase`}
          />
        </IonButtons>
      )}
      {props.children}
      {!props.nextButtonDisabled && (
        <IonButtons slot="end">
          <IonButton
            color={props.buttonsColor ?? "tertiary"}
            className={`${styles.navbarNextBtn} next secondary-font ion-text-uppercase`}
          >
            {t("ui.next")}
          </IonButton>
        </IonButtons>
      )}
    </IonToolbar>
  );
};
export default Navbar;
