import { useTranslation } from "react-i18next";
import WindowWithStroke from "../../window-with-stroke/window-with-stroke";
import styles from "./endgame-window.module.css";

interface EndgameWindowProps {
  won: boolean;
  correctAnswer: string | JSX.Element;
  onWindowTouchStart?: () => void;
}

const EndgameWindow = (props: EndgameWindowProps) => {

  const { t } = useTranslation();

  const correctAnswer = props.correctAnswer

  return (
    props.won ? (
      <WindowWithStroke
        className={styles.endGameWindow}
        title={t('games.endgame.congratulations')}
        textNode={<div>
          {t('games.endgame.congratulationsDescription')}
          <div
            className="buttonHover"
            onClick={() => { if (props?.onWindowTouchStart) props.onWindowTouchStart() }}
            style={{ marginTop: "20px", textTransform: "uppercase", border: "3px solid #000", borderRadius: "50px", padding: "15px 30px", width: "200px", margin: "35px auto" }}>{t('ui.continue')}</div>
        </div>}
      />
    ) : (
      <WindowWithStroke
        className={styles.endGameWindow}
        title={t('games.endgame.wrongAnswer')}
        textNode={<div>
          {t('games.endgame.wrongAnswerDescription')}
          <p className={styles.correctAnswer}>{correctAnswer}</p>
          <div
            className="buttonHover"
            onClick={() => { if (props?.onWindowTouchStart) props.onWindowTouchStart() }}
            style={{ marginTop: "20px", textTransform: "uppercase", border: "3px solid #000", borderRadius: "50px", padding: "15px 30px", width: "200px", margin: "0 auto" }}>{t('ui.continue')}</div>
        </div>}
      />
    )

  )
}

export default EndgameWindow;