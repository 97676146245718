import { IonContent, IonPage } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
// import 'swiper/swiper.min.css';
import 'swiper/css';
import { Routes } from '../../routes';
import styles from './intro-page.module.css';

const IntroPage = () => {

    const history = useHistory()

    return (
        <IonPage>
            <IonContent>
                <div className={styles.introContainer} onTouchStart={() => history.push(Routes.TUTORIAL)}>
                <p className={styles.introText}>
                    <Trans i18nKey='intro' />
                </p>
                </div>
            </IonContent>
        </IonPage>
    );
};
export default IntroPage;