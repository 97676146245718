import React from 'react';
import styles from './box.module.css';

interface BoxProps extends React.PropsWithChildren<{
  className?: string;
}> {}

const Box = (props: BoxProps) => {

  return (
    <div
      className={`${styles.box} ${props?.className}`}>
      {props.children}
    </div>
  );
};
// style={{'--background': 'magenta'}}
export default Box;
