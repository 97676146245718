import { Locale } from "../contexts/localization.context.models";

export enum GAME_TYPE {
  RIDDLE = "riddle",
  DRAWINGS_AND_WORDS = "drawingsAndWords",
  SINGLE_CHOICE_QUIZ = "singleChoiceQuiz",
  MULTIPLE_CHOICE_QUIZ = "multipleChoiceQuiz",
  TIMED_QUIZ = "timedQuiz",
  REORDER_ITEMS = "reorderItems",
  REORDER_LETTERS = "reorderLetters",
  HANGMAN = "hangman",
  REBUS = "rebus",
  PHOTO_QUIZ = "photoQuiz",
}

export interface GameLocale {
  title: string;
  description: string;
  config: string;
  hint: string;
  locale: Locale;
}

export interface Game {
  type: GAME_TYPE;
  order: number;
  locales: GameLocale[];
}
