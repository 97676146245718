import { TextFieldTypes } from '@ionic/core';
import { IonInput, IonLabel } from '@ionic/react';
import styles from './simple-input-field.module.css';

export interface SimpleInputFieldProps {
  type: TextFieldTypes;
  className?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  minlength?: number;
  maxlenght?: number;
  label?: string;
  errorMsg?: string;
  onKeyDown?: (event: any) => void;
  onChange?: (event: any) => void;
  onInput?: (event: any) => void;
  onFocus?: (event: any) => void;
}

const SimpleInputField = (props: SimpleInputFieldProps) => {

  return (
    <div className={`${props?.className} ion-margin-top`}>
      { !!props.label ? <IonLabel className={`${styles.inputLabel} ion-text-start secondary-font`} position='fixed'><span className={styles.inputLabelText}>{props.label}</span></IonLabel> : null }
      <IonInput
        disabled={props.disabled}
        color='medium'
        type={props.type}
        placeholder={props.placeholder}
        required={props?.required}
        minlength={props?.minlength}
        maxlength={props?.maxlenght}
        className={`${styles.simpleInputField} ion-padding`}
        onKeyDown={(e: any)=> {if (props?.onKeyDown) props.onKeyDown(e)}}
        onInput={(e: any)=> {if (props?.onInput) props.onInput(e)}}
        onIonChange={(e: any)=> {if (props?.onChange) props.onChange(e)}}
        onIonFocus={(e: any)=> {if (props?.onFocus) props.onFocus(e)}}
      />
      {!!props.errorMsg ? <span className={styles.simpleInputFieldError}>{props.errorMsg}</span> : <div className={styles.simpleInputFieldErrorSpacer}/>}
    </div>
  );
};
export default SimpleInputField;
