import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonPage,
  IonToolbar,
  IonCheckbox,
} from "@ionic/react";
import { eye, eyeOff, headset } from "ionicons/icons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { date, object, string } from "yup";
import InputFieldController, {
  InputFieldControllerProps,
} from "../../../components/input-field-controller/input-field-controller";
import MyButtonWithBorder from "../../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../../components/my-modal/my-modal";
import { User } from "../../../models/contexts/user.context.model";
import { Routes } from "../../../routes";
import { post } from "../../../services/http-service";
import { ENDPOINT_SIGNUP } from "../../../variables";
import styles from "./signup-page.module.css";

const SignupPage = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const resolverSchema = object({
    email: string().required().email(),
    username: string().required().min(3, t("access.usernameFormat")),
    password: string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
        t("access.passwordFormat")
      ),
    city: string().required(),
    birthday: date()
      .min(new Date(1900, 1, 1), "unaccepted date")
      .max(new Date(), "unaccepted date"),
    // birthday: string().required().matches(/^\d{2}\/\d{2}\/\d{4}$/g, {message: 'date is in the wrong format, should be mm/dd/yyyy'})
  }).required();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resolverSchema) });

  const customErrorsInitialState = {
    terms: ""
  }
  const customErrors = {
    terms: t("errors.terms")
  }

  const [signupStatus, setSignupStatus] = useState("not_sent");
  const [signupError, setSignupError] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);
  const [formCustomErrors, setFormCustomErrors] = useState(customErrorsInitialState)

  const formFields: InputFieldControllerProps[] = [
    {
      control,
      label: "E-MAIL *",
      name: "email",
      type: "text",
      placeholder: "e-mail",
    },
    {
      control,
      label: "USERNAME *",
      name: "username",
      type: "text",
      placeholder: "username",
    },
    {
      control,
      label: "PASSWORD *",
      name: "password",
      type: showPassword ? "text" : "password",
      placeholder: "password",
    },
    {
      control,
      label: `${t("labels.city").toUpperCase()} *`,
      name: "city",
      type: "text",
      placeholder: t("labels.city"),
    },
    {
      control,
      label: `${t("labels.birthday").toUpperCase()}`,
      name: "birthday",
      type: "date",
    },
  ];

  const submit = async (e: any) => {
    if (signupStatus !== 'pending') {
      // checkbox validation
      if (termsAccepted !== true) {
        setFormCustomErrors({ terms: customErrors.terms })
      } else {
        setFormCustomErrors(customErrorsInitialState)
        try {
          setSignupStatus("pending");
          const signupPayload: any = {
            email: e.email,
            username: e.username,
            password: e.password,
            city: e.city,
            terms: termsAccepted,
            newsletter: newsletterAccepted
          };
          if (e.birthday) {
            signupPayload["birthday"] = new Date(e.birthday).getTime().toString();
          }
          const signupRes = await post<{
            error: boolean;
            user?: User;
            msg?: string;
            code?: string;
          }>({
            endpoint: ENDPOINT_SIGNUP,
            data: signupPayload,
          });
          if (signupRes.error) {
            setSignupError(signupRes.code);
            setSignupStatus("failure");
          } else {
            // history.push(Routes.LOGIN);
            setSignupStatus("success");
          }
        } catch (err) {
          setSignupStatus("failure");
          setSignupError("generic");
          // TODO: to handle
        }
      }
    }
  };

  const resetSignup = () => {
    setSignupStatus("not_sent");
    setSignupError(undefined);
  };

  const backToLoginClick = () => {
    history.push(Routes.ACCESS);
  };

  const handleTermsChange = (event: any) => {
    setTermsAccepted(event.target.checked)
    let errors = formCustomErrors
    errors.terms = (event.target.checked) ? customErrorsInitialState.terms : customErrors.terms
    setFormCustomErrors(errors)
  }

  const handleNewsletterChange = (event: any) => {
    setNewsletterAccepted(event.target.checked)
  }
  return (
    <IonPage>
      {/* <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons>
            <IonButton
              routerLink={Routes.ACCESS}
              color="tertiary"
              className={`${styles.loginBtn} secondary-font ion-text-uppercase`}
            >
              {t("ui.back")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader> */}
      <IonContent>
        <div className={`${styles.signupFormContainer} ion-padding`}>
          {/* <IonImg
            src="assets/logo.svg"
            alt="Zonzo logo"
            className={styles.logo}
          ></IonImg> */}
          <h1 style={{ width: '100%', textAlign: 'center' }}>
            <img src="/back.png"
              onClick={() => backToLoginClick()} style={{ float: 'left', height: '30px', position: 'absolute', top: '40px', left: '35px' }} />
            {t("access.register")}
          </h1>
          <form className={styles.signupForm} onSubmit={handleSubmit(submit)}>
            {formFields.map((field) => (
              <IonItem
                className={styles.inputItem}
                lines="none"
                key={field.name}
              >
                {field.name === "password" ? (
                  <InputFieldController
                    {...field}
                    onIconClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? eyeOff : eye}
                    errorMsg={
                      !!errors[field.name]?.message
                        ? String(errors[field.name]?.message)
                        : undefined
                    }
                    className={
                      !!errors[field.name]?.message
                        ? styles.inputFieldError
                        : styles.inputField
                    }
                  />
                ) : field.name === "username" ? (
                  <InputFieldController
                    {...field}
                    errorMsg={
                      !!errors[field.name]?.message
                        ? String(errors[field.name]?.message)
                        : undefined
                    }
                    className={
                      !!errors[field.name]?.message
                        ? styles.inputFieldError
                        : styles.inputField
                    }
                  />
                ) : (
                  <InputFieldController
                    {...field}
                    className={
                      !!errors[field.name]?.message
                        ? styles.inputFieldError
                        : styles.inputField
                    }
                  />
                )}
              </IonItem>
            ))}
            <div style={{ padding: "1rem" }}>
              <div style={{ display: "flex", textAlign: "left" }}>
                <div>
                  <IonCheckbox onClick={handleTermsChange} className={(formCustomErrors.terms) ? styles.customCheckbox + ' ' + styles.customCheckboxError : styles.customCheckbox}></IonCheckbox>
                </div>
                <span style={{ fontSize: "14px" }}>{t("privacy.agree").split(':first')[0]}<a
                  target="_blank"
                  style={{ textDecoration: "underline", color: "#000", height: "15px", fontSize: "14px", margin: "0 0 6px 0" }}
                  color="dark"
                  href={"https://zonzoapp.com/termini-e-condizioni/"}
                >
                  {t("privacy.terms")}
                </a> {t("privacy.agree").split(':first')[1].split(':second')[0]}<a
                  style={{ textDecoration: "underline", color: "#000", height: "15px", fontSize: "14px", margin: "0 0 6px 0" }}
                  color="dark"
                  target="_blank"
                  href={"https://zonzoapp.com/privacy-policy/"}
                >
                    {t("privacy.policy")}
                  </a>*</span>
              </div>
              {formCustomErrors.terms &&
                <p style={{ margin: '0 0 0 1.9rem', color: "var(--error-color)" }}>{formCustomErrors.terms}</p>
              }
            </div>
            <div style={{ display: "flex", padding: "1rem" }}>
              <div>
                <IonCheckbox onClick={handleNewsletterChange} className={styles.customCheckbox}></IonCheckbox>
              </div>
              <span style={{ fontSize: "14px" }}>{t("privacy.newsletter").split(':link')[0]}<a
                style={{ textDecoration: "underline", color: "#000", height: "15px", fontSize: "14px", margin: "0 0 6px 0" }}
                color="dark"
                target="_blank"
                href={"https://zonzoapp.com/privacy-policy/"}
              >
                {t("privacy.policy")}
              </a> </span>
            </div>
            <MyButtonWithBorder
              disabled={signupStatus === "pending"}
              size="large"
              color="secondary"
              type="submit"
              className={`${styles.registerBtn} ion-margin-top ion-text-uppercase`}
            >
              {t("access.register")}
            </MyButtonWithBorder>
          </form>
        </div>
      </IonContent>
      <MyModal
        containerClassName={styles.signupEndModal}
        isOpen={signupStatus === "failure"}
        onCloseClick={() => resetSignup()}
      >
        {
          <>
            <p>{t(`errors.${signupError}`)}</p>
            <MyButtonWithBorder
              size="large"
              color="secondary"
              className="ion-margin-top ion-text-uppercase"
              onClick={() => resetSignup()}
            >
              {t("ui.back")}
            </MyButtonWithBorder>
          </>
        }
      </MyModal>
      <MyModal
        containerClassName={styles.signupEndModal}
        isOpen={signupStatus === "success"}
      >
        {
          <>
            <p>{t("access.signupSuccess")}</p>
            <MyButtonWithBorder
              size="large"
              color="secondary"
              className="ion-margin-top ion-text-uppercase"
              onClick={() => backToLoginClick()}
            >
              {t("access.login")}
            </MyButtonWithBorder>
          </>
        }
      </MyModal>
    </IonPage>
  );
};

export default SignupPage;
