import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonButton
} from "@ionic/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import BoxedTitle from "../../components/boxed-title/boxed-title";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../components/my-modal/my-modal";
import Navbar from "../../components/navbar/navbar";
import { SelectionContext } from "../../contexts/selection.context";
import { UserContext } from "../../contexts/user.context";
import { Routes } from "../../routes";
import { post, get } from "../../services/http-service";
import { ENDPOINT_CREATE_PAYMENT_INTENT, ENDPOINT_IAP } from "../../variables";
import PaymentForm from "./payment-form";
import styles from "./payment-page.module.css";
// const { AnalyticsPlugin } = Plugins;
import { Facebook } from "@awesome-cordova-plugins/facebook";
import appConfig from "../../config.json";

// declare var FB: any;

const PaymentPage = () => {
  const userContext = useContext(UserContext);
  const selectionContext = useContext(SelectionContext);

  const history = useHistory();
  const location = useLocation();

  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState<string>();
  const [paymentSucceded, setPaymentSucceded] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation();
  const [pmEnabled, setPmEnabled] = useState(true);

  useEffect(() => {
    if (location.pathname !== Routes.PAYMENT) return;

    reset();

    async function initStripe() {
      console.log("init stripe");
      try {
        setIsPending(true);
        const stripeInstance = await loadStripe(
          "pk_live_51KuBVtE0g3wzF0HsfqTH0UsyiyU2r4U6mzzAiPkoiJwDuIP73hUi2dutoBoFXCkEH0YBIFyP5YC4d0CUWwPeIBdh00FWj5FopY"
        );
        setStripe(stripeInstance);

        const intent = await post<any>({
          endpoint: ENDPOINT_CREATE_PAYMENT_INTENT,
          bearerToken: userContext.user.token,
          data: {
            itinerary: {
              id: selectionContext.selectedItinerary?.id,
              cost: selectionContext.selectedItinerary?.cost,
            },
            userId: userContext.user.id,
          },
        });
        setClientSecret(intent.clientSecret);
        setIsPending(false);
      } catch (err) {
        // TODO: handle error case
        console.log("err: ", err);
        setIsPending(false);
      }
    }

    async function initPaymentForm() {
      setIsPending(true);
      try {
        const res = await get<any>({ endpoint: ENDPOINT_IAP });
        if (res?.v === 1 && appConfig.version === res?.app_v) {
          setPmEnabled(false);
          setIsPending(false);
        } else {
          setPmEnabled(true);
          initStripe();
        }
      } catch {
        setPmEnabled(true);
        initStripe();
      }
    }

    initPaymentForm();
  }, [location]);

  function reset() {
    setPaymentSucceded(false);
    setIsPending(false);
    setStripe(null);
    setClientSecret(undefined);
  }

  async function onPaymentSuccess() {
    // AnalyticsPlugin.sendFacebookEvent({
    //   value: "itinerary_purchased",
    // });
    Facebook.logEvent("itinerary_purchased", {});
    // await GoogleAnalytics.trackEvent("Itinerary", "Purchased");
    // FB.AppEvents.logEvent("fb_mobile_itinerary_purchased");
    // Facebook.logEvent("fb_mobile_itinerary_purchased")
    //   .then(() => console.log("Sent to Facebook Analytics"))
    //   .catch((err) => console.log("fb send event err:", err));
    setPaymentSucceded(true);
  }
  function onSuccessButtonClick() {
    history.push(Routes.USER_ITINERARIES);
  }

  async function onPaymentError() {
    // TODO: handle error case
  }

  const stripeOptions = {
    clientSecret,
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar nextButtonDisabled={true} toolbarColor="primary" />
      </IonHeader>
      <IonContent>
        <div className={styles.upperSectionContainer}>
          <BoxedTitle className={styles.itineraryTitle}>
            {selectionContext.selectedItinerary?.locales[0].name}
          </BoxedTitle>
          {pmEnabled &&
            <span className={styles.costText}>
              {selectionContext.selectedItinerary?.cost} EURO
            </span>
          }
        </div>
        <div className={styles.bottomSectionContainer}>
          {isPending ? (
            <IonSpinner />
          ) : pmEnabled && !!clientSecret ? (
            <Elements options={stripeOptions} stripe={stripe}>
              <PaymentForm
                clientSecret={clientSecret}
                onSuccess={() => onPaymentSuccess()}
                onError={() => onPaymentError()}
              />
            </Elements>
          ) : !pmEnabled ? (
            <span>{t("itinerarySelection.physicalStore")} <IonButton
              fill="clear"
              style={{ textDecoration: "underline" }}
              color="dark"
              className={`${styles.menuButton} ion-text-uppercase`}
              href={"https://goo.gl/maps/jrG6HU7ypLsD2DUb7"}
            >
              Corso XXV Aprile, 42 – Ragusa Ibla
            </IonButton></span>
          ) : (
            <span>An error occurred while creating the payment session.</span>
          )}
        </div>
        <MyModal
          containerClassName={styles.successModal}
          isOpen={paymentSucceded}
        >
          <IonImg
            className={styles.successModalImage}
            src="assets/payment/present.svg"
          ></IonImg>
          <p>{t("payment.itineraryPurchased")}</p>
          <MyButtonWithBorder
            onClick={() => onSuccessButtonClick()}
            className={`${styles.successModalButton} ion-text-uppercase`}
            color="secondary"
          >
            {t("ui.play")}
          </MyButtonWithBorder>
        </MyModal>
      </IonContent>
    </IonPage>
  );
};

export default PaymentPage;
