import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenu,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useRef } from "react";
import { Routes } from "../../routes";
import styles from "./menu.module.css";

interface MenuButton {
  label: string;
  icon?: string;
  route?: Routes;
  onButtonClick?: () => void;
  link?: string;
  underlined?: boolean
}

interface MenuProps {
  topButtons: MenuButton[];
  bottomButtons?: MenuButton[];
}

const Menu = (props: MenuProps) => {
  const menuRef = useRef<HTMLIonMenuElement>(null);

  const topButtonsHtml = props.topButtons.map((el, index) => (
    <IonButton
      key={index}
      routerLink={el?.route ?? undefined}
      onClick={() => {
        if (el?.onButtonClick) {
          el.onButtonClick();
          menuRef.current?.close();
        }
      }}
      fill="clear"
      color="dark"
      className={`${styles.menuButton} ion-text-uppercase`}
    >
      {el.label}
      {el?.icon && <IonIcon className={styles.menuButtonIcon} icon={el.icon} />}
    </IonButton>
  ));
  let bottomButtonsHtml;
  if (props.bottomButtons) {
    bottomButtonsHtml = props.bottomButtons.map((el, index) => {
      if (el.link == null) {
        return (
          <IonButton
            key={index}
            routerLink={el?.route ?? undefined}
            onClick={() => {
              if (el?.onButtonClick) {
                el.onButtonClick();
                menuRef.current?.close();
              }
            }}
            fill="clear"
            style={{ textDecoration: (el.underlined === false) ? 'none' : "underline" }}
            color="dark"
            className={`${styles.menuButton} ion-text-uppercase`}
          >
            {el.label}
            {el?.icon && (
              <IonIcon className={styles.menuButtonIcon} icon={el.icon} />
            )}
          </IonButton>
        )
      } else {
        return (
          <IonButton
            key={index}
            fill="clear"
            style={{ textDecoration: (el.underlined === false) ? 'none' : "underline" }}
            color="dark"
            className={`${styles.menuButton} ion-text-uppercase`}
            href={el.link}
          >
            {el.label}
            {el?.icon && (
              <IonIcon className={styles.menuButtonIcon} icon={el.icon} />
            )}
          </IonButton>
        )
      }
    });
  }

  return (
    <IonMenu ref={menuRef} contentId="main-content">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="end">
            <IonButton onClick={() => menuRef.current?.close()}>
              <IonIcon
                slot="icon-only"
                color="tertiary"
                icon={closeOutline}
              ></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle className={styles.menuTitle}>MENU</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className={styles.buttonsCtn}>
          <div>{topButtonsHtml}</div>
          <div>{bottomButtonsHtml}</div>
        </div>
      </IonContent>
    </IonMenu>
  );
};
export default Menu;
