export enum Routes {
  ACCESS = "/access",
  LOGIN = "/login",
  RECOVER_PASSWORD = "/recover-password",
  SIGNUP = "/signup",
  DELETE_ACCOUNT = "/delete-account",
  INTRO = "/intro",
  TUTORIAL = "/tutorial",
  LANGUAGE_SELECTION = "/language-selection",
  CHOOSE_ITINERARY = "/choose-itinerary",
  CITY_SELECTION = "/city-selection",
  TAG_SELECTION = "/tag-selection",
  ITINERARY_SELECTION = "/itinerary-selection",
  SELECTED_TAG_INTRO = "/selected-tag-intro",
  SELECTED_ITINERARY_INTRO = "/selected-itinerary-intro",
  CITY_ITINERARIES = "/city-itineraries",
  PAYMENT = "/payment",
  WALLET = "/wallet",
  NFTS = "/nfts",
  QR_CODE_SCANNER = "/qr-code-scanner",
  USER_ITINERARIES = "/user-itineraries",
  WAYPOINT_INTRO = "/waypoint/intro",
  WAYPOINT_RIDDLE = "/waypoint/riddle",
  WAYPOINT_GAME = "/waypoint/game",
  WAYPOINT_GAME_MATCH = "/waypoint/game-match",
  WAYPOINT_ANECDOTE = "/waypoint/anecdote",
  WAYPOINT_RECAP = "/waypoint/recap",
  ITINERARY_COMPLETE = "/itinerary-complete",
  LEADERBOARD = "/leaderboard",
}
