import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import MyButtonWithBorder from '../../components/my-button-with-border/my-button-with-border';
import { Routes } from '../../routes';
import styles from './choose-itinerary.module.css';

const ChooseItineraryPage = () => {

    const { t } = useTranslation();

return (
    <IonPage>
        {/* <IonHeader className='ion-no-border'>
        <IonToolbar>
          <IonButtons slot='start'>
              <IonButton color='tertiary' className='back secondary-font'>{t('ui.back')}</IonButton>
          </IonButtons>
          <IonButtons slot='end'>
              <IonButton color='tertiary' className='next secondary-font'>{t('ui.next')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader> */}
        <IonContent>
        <div className={styles.chooseItineraryContainer}>
            <p className={styles.chooseItineraryDescription}>{t('chooseItinerary.description')}</p>
            <MyButtonWithBorder size='large' color='secondary' routerLink={Routes.CITY_SELECTION} className={`${styles.discoverButton} ion-text-uppercase`}>{t('ui.discover')}</MyButtonWithBorder>
        </div>
        </IonContent>
    </IonPage>
  );
};
export default ChooseItineraryPage;