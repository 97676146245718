import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translations.json";
import it from "./locales/it/translations.json";

i18n.use(initReactI18next).init({
  fallbackLng: "it",
  lng: "it",
  resources: {
    it: {
      translations: it,
    },
    en: {
      translations: en,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["it", "en"];

export default i18n;
