import { IonSpinner, isPlatform } from "@ionic/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./game-intro.module.css";

interface GameIntroProps {
  upperImageUrl: string;
  gameImage: {
    url: string;
    alt: string;
  };
  gameName: string;
  gameDescription: string;
}

const GameIntro = (props: GameIntroProps) => {
  return (
    <>
      <div>
        <LazyLoadImage
          className={styles.upperImage}
          src={props.upperImageUrl}
          alt="Game intro upper image"
          placeholder={<IonSpinner className="centered" />}
        ></LazyLoadImage>
        <p className={`${styles.upperText} ion-text-uppercase ion-text-center`}>
          {props.gameName}
        </p>
      </div>
      <LazyLoadImage
        src={props.gameImage.url}
        className={styles.gameImage}
        alt={props.gameImage.alt}
        placeholder={<IonSpinner />}
      ></LazyLoadImage>
      <p
        className={`zonzo-scrollbar-primary ${styles.description} ${isPlatform("android") ? styles.descriptionAndroid : ""
          } ion-text-start`}
        dangerouslySetInnerHTML={{ __html: props.gameDescription ?? "" }}
      ></p>
    </>
  );
};

export default GameIntro;
