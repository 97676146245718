import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import styles from "./my-modal.module.css";
interface MyModalProps
  extends React.PropsWithChildren<{
    isOpen: boolean;
    avoidBackdropSensitive?: boolean;
    backgroundClassName?: string;
    containerClassName?: string;
    onBackgroundClick?: () => void;
    onCloseClick?: () => void;
  }> {}

const MyModal = (props: MyModalProps) => {
  const location = useLocation();
  // useEffect(() => {
  //   return function cleanup() {
  //     utilsContext.toggleBackdrop(false);
  //   };
  // }, []);

  useEffect(() => {
    if (!props?.avoidBackdropSensitive) {
      if (props.isOpen) {
        document
          .querySelectorAll(".backdrop-sensitive")
          .forEach((el) => el.classList.toggle("backdrop-active", true));
      } else {
        document
          .querySelectorAll(".backdrop-sensitive")
          .forEach((el) => el.classList.toggle("backdrop-active", false));
      }
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (!props?.avoidBackdropSensitive) {
      if (props.isOpen) {
        document
          .querySelectorAll(".backdrop-sensitive")
          .forEach((el) => el.classList.toggle("backdrop-active", false));
      }
    }
  }, [location]);

  return (
    <>
      {props.isOpen ? (
        <div className={styles.myModalRoot}>
          <div
            onClick={() => {
              if (props?.onBackgroundClick) props.onBackgroundClick();
            }}
            className={`${styles.myModalBackground} ${props.backgroundClassName}`}
          />
          <div
            className={`${styles.myModalContainer} ${props.containerClassName}`}
          >
            {props?.onCloseClick && (
              <IonButton
                onClick={() => {
                  if (props?.onCloseClick) props.onCloseClick();
                }}
                className={styles.myModalCloseButton}
                fill="clear"
              >
                <IonIcon
                  className={styles.myModalCloseIcon}
                  slot="icon-only"
                  color="tertiary"
                  icon={closeOutline}
                ></IonIcon>
              </IonButton>
            )}
            {props.children}
          </div>
        </div>
      ) : null}
    </>
  );
};
export default MyModal;
