import React from "react";
import {
  ItineraryContextPayload,
  WaypointPhase,
} from "../models/contexts/itinerary.context.models";

export const ItineraryContext = React.createContext<ItineraryContextPayload>({
  continue: () => {},
  startItinerary: () => {},
  endItinerary: () => {},
  addScore: (score: number) => {},
  waypointPhase: WaypointPhase.INTRO,
  gameIndex: 0,
  currentScore: 0,
});
