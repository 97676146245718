import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
} from "@ionic/react";
import { helpOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { v4 } from "uuid";
import DrawingsAndWords from "../../../components/games/drawings-and-words/drawings-and-words";
import Hangman from "../../../components/games/hangman/hangman";
import MultipleChoiceQuiz from "../../../components/games/multiple-choice-quiz/multiple-choice-quiz";
import PhotoQuiz from "../../../components/games/photo-quiz/photo-quiz";
import Rebus from "../../../components/games/rebus/rebus";
import ReorderItems from "../../../components/games/reorder-items/reorder-items";
import ReorderLetters from "../../../components/games/reorder-letters/reorder-letters";
import SingleChoiceQuiz from "../../../components/games/single-choice-quiz/single-choice-quiz";
import TimedQuiz from "../../../components/games/timed-quiz/timed-choice-quiz";
import MyModal from "../../../components/my-modal/my-modal";
import Navbar from "../../../components/navbar/navbar";
import { ItineraryContext } from "../../../contexts/itinerary.context";
import { GAME_TYPE } from "../../../models/api/games.models";
import { Routes } from "../../../routes";
import { TAG_IMAGES_FOLDER } from "../../../variables";
import styles from "./waypoint-game-match.module.css";
const WaypointGameMatchPage = () => {
  const { t } = useTranslation();
  const itineraryContext = useContext(ItineraryContext);
  const [gameEnded, setGameEnded] = useState<boolean>(false);
  const [hintModalOpen, setHintModalOpen] = useState<boolean>(false);
  const [myIndex, setMyIndex] = useState<number>(0);
  const [myKey, setMyKey] = useState<string>("");
  const [hintKey, setHintKey] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_GAME_MATCH)) return;

    setMyKey(v4());
    setHintKey(v4());
    setGameEnded(false);
    setHintModalOpen(false);
    setMyIndex(itineraryContext.gameIndex);
  }, [location]);

  if (!itineraryContext?.currentWaypoint?.games.length || myIndex < 0)
    return fallbackPage();

  const games = itineraryContext?.currentWaypoint
    ? itineraryContext.currentWaypoint.games
    : [];

  if (myIndex >= games.length) return fallbackPage();

  const tagImageUrl = `${TAG_IMAGES_FOLDER}/${itineraryContext.currentItinerary?.tags[0].logoName}`;
  const currentGameId = myIndex;
  const currentGameLocale =
    games.length > currentGameId
      ? games[currentGameId].locales?.[0]
      : undefined;
  const currentGameConfig = !!currentGameLocale
    ? JSON.parse(currentGameLocale.config)
    : undefined;

  // const uniqueId = `${itineraryContext?.currentItinerary?.id}-${itineraryContext?.currentWaypoint?.id}-${currentGameId}`;

  const onGameEnded = (score: number) => {
    if (!gameEnded) {
      itineraryContext.addScore(score);
      setGameEnded(true);
    }
  };

  let gameTag;
  switch (games[currentGameId].type) {
    case GAME_TYPE.SINGLE_CHOICE_QUIZ:
      gameTag = (
        <>
          <SingleChoiceQuiz
            key={myKey}
            config={currentGameConfig}
            onEndgameTouch={() => itineraryContext.continue()}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          ></SingleChoiceQuiz>
          {/* <MyButtonWithBorder disabled={!gameEnded} onClick={() => itineraryContext.continue()} size='default' color='secondary' className={`${styles.continueButton} ion-text-uppercase`}>{t('ui.continue')}</MyButtonWithBorder> */}
        </>
      );
      break;
    case GAME_TYPE.TIMED_QUIZ:
      gameTag = (
        <>
          <TimedQuiz
            key={myKey}
            config={currentGameConfig}
            onEndgameTouch={() => itineraryContext.continue()}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          ></TimedQuiz>
        </>
      );
      break;
    case GAME_TYPE.MULTIPLE_CHOICE_QUIZ:
      gameTag = (
        <>
          <MultipleChoiceQuiz
            key={myKey}
            config={currentGameConfig}
            onEndgameTouch={() => itineraryContext.continue()}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          ></MultipleChoiceQuiz>
        </>
      );
      break;
    case GAME_TYPE.DRAWINGS_AND_WORDS:
      gameTag = (
        <>
          <DrawingsAndWords
            key={myKey}
            config={currentGameConfig}
            onEndgameTouch={() => itineraryContext.continue()}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          ></DrawingsAndWords>
        </>
      );
      break;
    case GAME_TYPE.REBUS:
      gameTag = (
        <>
          <Rebus
            key={myKey}
            config={currentGameConfig}
            onEndgameTouch={() => itineraryContext.continue()}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          ></Rebus>
        </>
      );
      break;
    case GAME_TYPE.PHOTO_QUIZ:
      gameTag = (
        <>
          <PhotoQuiz
            key={myKey}
            config={currentGameConfig}
            onEndgameTouch={() => itineraryContext.continue()}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          ></PhotoQuiz>
        </>
      );
      break;
    case GAME_TYPE.HANGMAN:
      gameTag = (
        <>
          <Hangman
            key={myKey}
            config={currentGameConfig}
            onEndgameTouch={() => itineraryContext.continue()}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          ></Hangman>
        </>
      );
      break;
    case GAME_TYPE.REORDER_ITEMS:
      gameTag = (
        <>
          <ReorderItems
            key={myKey}
            config={currentGameConfig}
            onEndgameTouch={() => itineraryContext.continue()}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          ></ReorderItems>
        </>
      );
      break;
    case GAME_TYPE.REORDER_LETTERS:
      gameTag = (
        <>
          <ReorderLetters
            key={myKey}
            config={currentGameConfig}
            onEndgameTouch={() => itineraryContext.continue()}
            onWon={(arg) => onGameEnded(arg)}
            onLost={(arg) => onGameEnded(arg)}
            imageUrl={tagImageUrl ?? ""}
          ></ReorderLetters>
        </>
      );
      break;
  }

  function fallbackPage() {
    return (
      <IonPage>
        <IonHeader className="ion-no-border">
          <Navbar
            nextButtonDisabled={true}
            backButtonDisabled={true}
            toolbarColor="primary"
          />
        </IonHeader>
        <IonContent color="primary"></IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar
          nextButtonDisabled={true}
          backButtonDisabled={true}
          toolbarColor="primary"
        >
          {
            <IonButtons className={styles.hintButtonCtn} slot="end">
              <IonButton
                onClick={() => setHintModalOpen(true)}
                color="tertiary"
                className={`${styles.hintBtn} next secondary-font`}
              >
                <IonIcon className={styles.helpIcon} icon={helpOutline} />
              </IonButton>
            </IonButtons>
          }
        </Navbar>
      </IonHeader>
      <IonContent color="primary">
        <div className={styles.waypointGameContainer}>
          {/* <div key={uniqueId} className={styles.waypointGameContainer}> */}
          {!!tagImageUrl && !!currentGameConfig ? (
            gameTag
          ) : (
            <p>{t("errors.unavailablePage")}</p>
          )}
        </div>

        <MyModal
          isOpen={hintModalOpen}
          containerClassName={styles.hintModal}
          onCloseClick={() => setHintModalOpen(false)}
          onBackgroundClick={() => setHintModalOpen(false)}
        >
          <p
            key={hintKey}
            className={`${styles.hintParagraph} zonzo-scrollbar-primary`}
          >
            {/* <p key={uniqueId} className={styles.hintParagraph}> */}
            {currentGameLocale?.hint}
          </p>
        </MyModal>
      </IonContent>
    </IonPage>
  );
};

export default WaypointGameMatchPage;
