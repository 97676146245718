import {
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonReorder,
  IonReorderGroup,
  IonSpinner,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { PLAY_STATE } from "../../../models/games/base.models";
import {
  ReorderItemsAnswer,
  ReorderItemsConfig,
  ReorderItemsGameState,
} from "../../../models/games/reorder-items.models";
import { Routes } from "../../../routes";
import MyWindow from "../../my-window/my-window";
import EndgameWindow from "../endgame-window/endgame-window";
import styles from "./reorder-items.module.css";

interface ReorderItemsProps {
  config: ReorderItemsConfig;
  imageUrl: string;
  onWon?: (arg0: number) => void;
  onLost?: (arg0: number) => void;
  onEndgameTouch?: () => void;
}

const ReorderItems = (props: ReorderItemsProps) => {
  const location = useLocation();

  const [gameState, setGameState] = useState<ReorderItemsGameState>({
    playState: PLAY_STATE.NOT_STARTED,
    score: 0,
  });
  const [currentOrderedItems, setCurrentOrderedItems] = useState<
    ReorderItemsAnswer[]
  >([]);

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_GAME_MATCH)) return;

    setGameState({ playState: PLAY_STATE.PLAYING, score: 0 });
    initItems();
  }, [location]);

  useEffect(() => {
    if (gameState.playState === PLAY_STATE.WON) {
      props.onWon?.(gameState.score);
    } else if (gameState.playState === PLAY_STATE.LOST) {
      props.onLost?.(gameState.score);
    }
  }, [props, gameState]);

  /**
   * When the answer is clicked, the state is updated with the new clicked value
   * @param answerState
   * @param answerIndex
   * @returns
   */

  function initItems() {
    let itemsToReorder = props.config.answers;
    let limit = 0;
    console.log("randomly sorting items");
    do {
      itemsToReorder.sort(() => Math.random() - 0.5);
      limit++;
    } while (areItemsOrdered(itemsToReorder) && limit < 5);
    setCurrentOrderedItems(itemsToReorder);
  }

  function areItemsOrdered(items: ReorderItemsAnswer[]) {
    if (items.length < 2) return true;

    for (let i = 1; i < items.length; i++) {
      if (items[i].order < items[i - 1].order) {
        return false;
      }
    }
    return true;
  }

  function swapItems(indexA: number, indexB: number) {
    const tmp = [...currentOrderedItems];
    const tmpA = tmp[indexA];
    if (indexA < indexB) {
      tmp.splice(indexB + 1, 0, tmpA);
      tmp.splice(indexA, 1);
    } else {
      tmp.splice(indexB, 0, tmpA);
      tmp.splice(indexA + 1, 1);
    }
    setCurrentOrderedItems(tmp);
  }

  function onItemReorder(e: any) {
    swapItems(e.detail.from, e.detail.to);
    e.detail.complete();
  }

  function onSendAnswer() {
    if (areItemsOrdered(currentOrderedItems)) {
      setGameState({
        ...gameState,
        score: props.config.reward,
        playState: PLAY_STATE.WON,
      });
    } else {
      setGameState({ ...gameState, playState: PLAY_STATE.LOST });
    }
  }

  // TODO: backend should check there are no duplicated answers when creating a game
  const answersHtml = currentOrderedItems.map((answer, i) => {
    return (
      <IonItem
        key={answer.order}
        lines="none"
        color="secondary"
        className={styles.answerItem}
      >
        <IonLabel>{answer.answer}</IonLabel>
        <IonReorder />
      </IonItem>
    );
  });

  const correctAnswerString = [...currentOrderedItems]
    .sort((a, b) => a.order - b.order)
    .map((el) => el.answer)
    .join(", ");

  return (
    <>
      <LazyLoadImage
        className={styles.upperImage}
        src={props.imageUrl}
        alt="Single choice quiz upper image"
        placeholder={<IonSpinner />}
      ></LazyLoadImage>
      {gameState.playState === PLAY_STATE.PLAYING ||
      gameState.playState === PLAY_STATE.NOT_STARTED ? (
        <MyWindow className={styles.quizWindow}>
          <p className={`${styles.questionText} ion-text-center`}>
            {props.config.question}
          </p>
          <IonReorderGroup
            className={styles.itemsReorderGroup}
            onIonItemReorder={(e) => onItemReorder(e)}
            disabled={false}
          >
            {answersHtml}
          </IonReorderGroup>
          <IonButton
            disabled={gameState.playState !== PLAY_STATE.PLAYING}
            className={styles.confirmButton}
            fill="clear"
            onClick={() => onSendAnswer()}
          >
            <IonImg
              class={styles.confirmImg}
              src="assets/games/tick.svg"
              alt="Confirm"
            ></IonImg>
          </IonButton>
        </MyWindow>
      ) : (
        <EndgameWindow
          correctAnswer={correctAnswerString}
          won={gameState.playState === PLAY_STATE.WON}
          onWindowTouchStart={() => {
            if (props?.onEndgameTouch) props.onEndgameTouch();
          }}
        />
      )}
    </>
  );
};

export default ReorderItems;
