export enum PLAY_STATE {
  NOT_STARTED = "notStarted",
  PLAYING = "playing",
  WON = "won",
  LOST = "lost",
}

export interface BaseGameState {
  playState: PLAY_STATE;
  score: number;
}

export interface QuizConfig {
  question: string;
  answers: QuizAnswer[];
  description: string;
  reward: number;
}

export interface QuizAnswer {
  answer: string;
  isCorrect: boolean;
}
