import { IonButton, IonImg, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { PLAY_STATE, QuizAnswer } from "../../../models/games/base.models";
import {
  MultipleChoiceQuizConfig,
  MultipleChoiceQuizGameState,
} from "../../../models/games/multiple-choice-quiz.models";
import { Routes } from "../../../routes";
import MyWindow from "../../my-window/my-window";
import EndgameWindow from "../endgame-window/endgame-window";
import AnswerButton from "./answer-button";
import styles from "./multiple-choice-quiz.module.css";

interface MultipleChoiceQuizProps {
  config: MultipleChoiceQuizConfig;
  imageUrl: string;
  onWon?: (arg0: number) => void;
  onLost?: (arg0: number) => void;
  onEndgameTouch?: () => void;
}

type MultipleChoiceQuizAnswerState = QuizAnswer & { clicked: boolean };

const MultipleChoiceQuiz = (props: MultipleChoiceQuizProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [answers, setAnswers] = useState<MultipleChoiceQuizAnswerState[]>([]);
  const [gameState, setGameState] = useState<MultipleChoiceQuizGameState>({
    playState: PLAY_STATE.NOT_STARTED,
    score: 0,
  });

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_GAME_MATCH)) return;

    setAnswers(
      props.config.answers.map<MultipleChoiceQuizAnswerState>((el) => ({
        ...el,
        clicked: false,
      }))
    );
    setGameState({ playState: PLAY_STATE.PLAYING, score: 0 });
  }, [location]);

  useEffect(() => {
    if (gameState.playState === PLAY_STATE.WON) {
      props.onWon?.(gameState.score);
    } else if (gameState.playState === PLAY_STATE.LOST) {
      props.onLost?.(gameState.score);
    }
  }, [props, gameState]);

  /**
   * When the answer is clicked, the state is updated with the new clicked value
   * @param answerState
   * @param answerIndex
   * @returns
   */
  function onClickAnswer(
    answerState: MultipleChoiceQuizAnswerState,
    answerIndex: number
  ) {
    if (gameState.playState !== PLAY_STATE.PLAYING) return;

    const tmp = [...answers];
    tmp[answerIndex] = { ...answerState, clicked: !answerState.clicked };
    setAnswers(tmp);
  }

  /**
   * if an answer is clicked but it is not correct, return false
   * @returns
   */
  function validateAnswers(): boolean {
    for (let i = 0; i < answers.length; i++) {
      if (
        (answers[i].clicked && !answers[i].isCorrect) ||
        (!answers[i].clicked && answers[i].isCorrect)
      ) {
        return false;
      }
    }
    return true;
  }

  function thereAreClickedAnswers(): boolean {
    return answers.some((el) => el.clicked);
  }

  /**
   * When I submit answers, they are validated and the game state is updated
   */
  function onSendAnswer() {
    if (validateAnswers()) {
      setGameState({
        ...gameState,
        score: props.config.reward,
        playState: PLAY_STATE.WON,
      });
    } else {
      setGameState({ ...gameState, playState: PLAY_STATE.LOST });
    }
  }

  // TODO: backend should check there are no duplicated answers when creating a game
  const answersHtml = answers.map((answer, i) => {
    let btnClass = "";
    if (answer.clicked) {
      btnClass = styles.clickedAnswer;
    }

    return (
      <AnswerButton
        className={btnClass}
        onClick={() => onClickAnswer(answer, i)}
        key={`${answer.answer}-${i}`}
      >
        {answer.answer}
      </AnswerButton>
    );
  });

  const correctAnswerString = props.config.answers
    .filter((el) => el.isCorrect)
    .map((el) => el.answer)
    .join(", ");

  return (
    <>
      <LazyLoadImage
        className={styles.upperImage}
        src={props.imageUrl}
        alt="Single choice quiz upper image"
        placeholder={<IonSpinner />}
      ></LazyLoadImage>
      {gameState.playState === PLAY_STATE.PLAYING ||
      gameState.playState === PLAY_STATE.NOT_STARTED ? (
        <MyWindow className={styles.quizWindow}>
          <p className={`${styles.questionText} ion-text-center`}>
            {props.config.question}
          </p>
          <div className={styles.answersCtn}>{answersHtml}</div>
          <IonButton
            disabled={
              gameState.playState !== PLAY_STATE.PLAYING ||
              !thereAreClickedAnswers()
            }
            className={styles.confirmButton}
            fill="clear"
            onClick={() => onSendAnswer()}
          >
            <IonImg
              className={styles.tickImg}
              src="assets/games/tick.svg"
              alt="Confirm"
            ></IonImg>
          </IonButton>
        </MyWindow>
      ) : (
        <EndgameWindow
          correctAnswer={correctAnswerString}
          won={gameState.playState === PLAY_STATE.WON}
          onWindowTouchStart={() => {
            if (props?.onEndgameTouch) props.onEndgameTouch();
          }}
        />
      )}
    </>
  );
};

export default MultipleChoiceQuiz;
