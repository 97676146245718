import { IonButton, IonImg, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { PLAY_STATE } from "../../../models/games/base.models";
import {
  TimedQuizConfig,
  TimedQuizGameState,
} from "../../../models/games/timed-quiz.models";
import { Routes } from "../../../routes";
import MyWindow from "../../my-window/my-window";
import SimpleInputField from "../../simple-input-field/simple-input-field";
import EndgameWindow from "../endgame-window/endgame-window";
import styles from "./timed-quiz.module.css";

interface TimedQuizProps {
  config: TimedQuizConfig;
  imageUrl: string;
  onWon?: (arg0: number) => void;
  onLost?: (arg0: number) => void;
  onEndgameTouch?: () => void;
}

const TimedQuiz = (props: TimedQuizProps) => {
  const [answer, setAnswer] = useState("");
  const [gameState, setGameState] = useState<TimedQuizGameState>({
    playState: PLAY_STATE.NOT_STARTED,
    score: 0,
    timeLeft: props.config.timeLimit,
  });
  const location = useLocation();
  const [myTimeout, setMyTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_GAME_MATCH)) return;

    if (!!myTimeout) {
      clearTimeout(myTimeout);
      setMyTimeout(undefined);
    }
    setGameState({
      playState: PLAY_STATE.PLAYING,
      score: 0,
      timeLeft: props.config.timeLimit,
    });
  }, [location]);

  useEffect(() => {
    switch (gameState.playState) {
      case PLAY_STATE.PLAYING:
        tick();
        break;
      case PLAY_STATE.WON:
        props.onWon?.(gameState.score);
        break;
      case PLAY_STATE.LOST:
        props.onLost?.(gameState.score);
        break;
    }
  }, [gameState.playState]);

  useEffect(() => {
    if (gameState.timeLeft > 0 && gameState.playState === PLAY_STATE.PLAYING) {
      tick();
    } else if (gameState.playState === PLAY_STATE.PLAYING) {
      setGameState({ ...gameState, playState: PLAY_STATE.LOST });
    }
  }, [gameState.timeLeft]);

  function tick() {
    const tmp = setTimeout(() => {
      if (gameState.playState === PLAY_STATE.PLAYING)
        setGameState({ ...gameState, timeLeft: gameState.timeLeft - 1 });
    }, 1000);

    setMyTimeout(tmp);
  }

  function onAnswer() {
    if (gameState.playState !== PLAY_STATE.PLAYING) return;

    if (!!myTimeout) {
      clearTimeout(myTimeout);
      setMyTimeout(undefined);
    }
    if (props.config.answer.toLowerCase() === answer.trim().toLowerCase()) {
      setGameState({
        ...gameState,
        score: props.config.reward,
        playState: PLAY_STATE.WON,
      });
    } else {
      setGameState({ ...gameState, playState: PLAY_STATE.LOST });
    }
  }

  function onChange(text: string) {
    setAnswer(text);
  }

  return (
    <>
      <LazyLoadImage
        className={styles.upperImage}
        src={props.imageUrl}
        alt="Single choice quiz upper image"
        placeholder={<IonSpinner />}
      ></LazyLoadImage>
      {gameState.playState === PLAY_STATE.PLAYING ||
      gameState.playState === PLAY_STATE.NOT_STARTED ? (
        <MyWindow className={styles.quizWindow}>
          <div>
            <p className={`${styles.questionText} ion-text-center`}>
              {props.config.question}
            </p>
            <SimpleInputField
              className={styles.inputField}
              onChange={(e) => onChange(e.target.value)}
              label=""
              type="text"
            ></SimpleInputField>
          </div>
          <div className={styles.quizWindowBottomSection}>
            <div className={styles.quizWindowTimeCounter}>
              {gameState.timeLeft}
            </div>
            <IonButton
              disabled={
                gameState.playState !== PLAY_STATE.PLAYING || answer === ""
              }
              className={styles.confirmButton}
              fill="clear"
              onClick={() => onAnswer()}
            >
              <IonImg
                className={styles.confirmImg}
                src="assets/games/tick.svg"
                alt="Confirm"
              ></IonImg>
            </IonButton>
          </div>
        </MyWindow>
      ) : (
        <EndgameWindow
          correctAnswer={props.config.answer}
          won={gameState.playState === PLAY_STATE.WON}
          onWindowTouchStart={() => {
            if (props?.onEndgameTouch) props.onEndgameTouch();
          }}
        />
      )}
    </>
  );
};

export default TimedQuiz;
