import { TextFieldTypes } from '@ionic/core';
import { IonButton, IonIcon, IonInput, IonLabel } from '@ionic/react';
import styles from './input-field.module.css';

export interface InputFieldProps {
  type: TextFieldTypes;
  placeholder?: string;
  className?: string;
  required?: boolean;
  minlength?: number;
  label: string;
  errorMsg?: string;
  icon?: string;
  onIconClick?: () => void;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
}

const InputField = (props: InputFieldProps) => {
  return (
    <div className={`${styles.inputFieldContainer} ion-margin-top`}>
      <IonLabel className={`${styles.inputLabel} ion-text-start secondary-font`} position='fixed'><span className={styles.inputLabelText}>{props.label}</span></IonLabel>
      <IonInput
        color='medium'
        type={props.type}
        placeholder={props.placeholder}
        required={props?.required}
        minlength={props?.minlength}
        className={`${styles.inputField} ${props.className} ion-padding`}
        onIonChange={(e: any)=> {if (props?.onChange) props.onChange(e.target.value)}}
        onIonFocus={(e: any)=> {if (props?.onFocus) props.onFocus(e.target.value)}}
      />
      { !!props.icon &&
        <IonButton fill='clear' color='medium' onClick={(e: any)=> {if (props?.onIconClick) props.onIconClick()}} className={styles.iconButton} slot="end" >
          <IonIcon icon={props.icon}></IonIcon>
        </IonButton>
      }
      {!!props.errorMsg ? <span className={styles.inputFieldError}>{props.errorMsg}</span> : <div className={styles.inputFieldErrorSpacer}/>}
    </div>
  );
};
export default InputField;
