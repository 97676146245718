import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import "@ionic/react/css/ionic-swiper.css";
import { closeOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { QrReader } from 'react-qr-reader';
import "swiper/css";
import MyAutocomplete from "../../components/my-autocomplete/my-autocomplete";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../components/my-modal/my-modal";
import Navbar from "../../components/navbar/navbar";
import { LocalizationContext } from "../../contexts/localization.context";
import { UserContext } from "../../contexts/user.context";
import { Itinerary } from "../../models/api/itineraries.models";
import { Routes } from "../../routes";
import { get, patch } from "../../services/http-service";
import {
  ENDPOINT_GET_PUBLISHED_ITINERARIES,
  ENDPOINT_GET_VALIDATE_DISCOUNT_TOKEN,
  ENDPOINT_PATCH_CONSUME_DISCOUNT_TOKEN,
} from "../../variables";
import styles from "./qr-code-scanner-page.module.css";

const QrCodeScannerPage = () => {
  const userContext = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);
  const [scanContent, setScanContent] = useState<string>("");
  const [scanPermissionGranted, setScanPermissionGranted] = useState<
    boolean | null
  >(null);
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);
  const [itinerariesList, setItinerariesList] = useState<Itinerary[]>([]);
  const [selectedItinerary, setSelectedItinerary] = useState<Itinerary>();
  const [isItinerariesFetchPending, setIsItinerariesFetchPending] =
    useState(false);
  const [consumePatchState, setConsumePatchState] = useState("not_sent");
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== Routes.QR_CODE_SCANNER) return;
    reset();
  }, [location]);

  useEffect(() => {
    async function onQrCodeScanned() {
      try {
        await get({
          endpoint: `${ENDPOINT_GET_VALIDATE_DISCOUNT_TOKEN}/${btoa(scanContent.replace("https://api.zonzoapp.com/discountToken/unlock/", ""))
            }`,
          bearerToken: userContext.user.token,
        });

        setIsTokenValid(true);
      } catch (err) {
        setIsTokenValid(false);
      }
      try {
        setIsItinerariesFetchPending(true);
        const itineraries = await get<Itinerary[]>({
          endpoint: `${ENDPOINT_GET_PUBLISHED_ITINERARIES}?locale=${localizationContext.locale ?? localizationContext.defaultLocale
            }`,
          bearerToken: userContext.user.token,
        });

        setItinerariesList(
          itineraries.filter((itinerary) => itinerary.locales.length > 0)
        );
        setIsItinerariesFetchPending(false);
      } catch (err) {
        setIsItinerariesFetchPending(false);
      }
    }

    if (scanContent) {
      onQrCodeScanned();
    }
  }, [scanContent]);

  function reset() {
    setIsItinerariesFetchPending(false);
    setScanContent("");
    setConsumePatchState("not_sent");
    setSelectedItinerary(undefined);
    setIsTokenValid(null);
    setScanPermissionGranted(null);
    setItinerariesList([]);
  }

  async function unlockItinerary() {
    setConsumePatchState("pending");
    try {
      await patch({
        endpoint: `${ENDPOINT_PATCH_CONSUME_DISCOUNT_TOKEN}`,
        bearerToken: userContext.user.token,
        data: {
          token: scanContent.replace("https://api.zonzoapp.com/discountToken/unlock/", ""),
          userId: userContext.user.id,
          itineraryId: selectedItinerary?.id,
        },
      });
      setConsumePatchState("success");
    } catch (err) {
      setConsumePatchState("failure");
    }
  }

  // async function openScanner() {
  //   console.log(`start scan`);
  //   const status = await BarcodeScanner.checkPermission({ force: true });

  //   if (status.granted) {
  //     setScanPermissionGranted(true);
  //     setIsScanning(true);
  //     await BarcodeScanner.hideBackground();
  //     document.documentElement.style.visibility = "hidden";
  //     const prevBackgroundColor = document.body.style.backgroundColor;
  //     document.body.style.backgroundColor = "transparent";
  //     const data = await BarcodeScanner.startScan({
  //       targetedFormats: [SupportedFormat.QR_CODE],
  //     });
  //     setIsScanning(false);
  //     document.documentElement.style.visibility = "visible";
  //     document.body.style.backgroundColor = prevBackgroundColor;
  //     if (data.hasContent) {
  //       const split = data.content?.split("/");
  //       setScanContent(split?.[split?.length - 1] ?? "");
  //     }
  //   } else {
  //     setScanPermissionGranted(false);
  //   }
  // }

  // async function closeScanner() {
  //   console.log("closing scanner");
  //   setIsScanning(false);
  //   document.documentElement.style.visibility = "visible";
  //   await BarcodeScanner.stopScan();
  //   BarcodeScanner.showBackground();
  //   console.log("scanner closed");
  // }

  function onSuccessButtonClick() {
    history.push(Routes.USER_ITINERARIES);
  }

  const myAutocompleteItems = itinerariesList.map((itinerary) => ({
    id: itinerary.id.toString(),
    name: itinerary.locales[0].name,
  }));

  const preScanContent =
    scanPermissionGranted || scanPermissionGranted == null ? (
      <div className={styles.qrCodeScannerContainer}>
        <p className={`${styles.upperDescription} ion-text-uppercase`}>
          {t("qrCodeScanner.description")}
        </p>
        <QrReader
          onResult={(result, error) => {
            if (!!result) {
              setScanContent(result?.getText() ?? "");
            }
          }}
          constraints={{ facingMode: "environment" }}
          className="qr-code-scanner"
        />
        <div></div>
      </div>
    ) : (
      <div className={styles.qrCodeScannerContainer}>
        <p className={`${styles.upperDescription} ion-text-uppercase`}>
          {t("qrCodeScanner.checkPermissions")}
        </p>
        <>???</>
      </div>
    );

  const postScanContent = (
    <div className={styles.postScanContainer}>
      {isTokenValid ? (
        isItinerariesFetchPending ? (
          <div>
            <IonSpinner />
          </div>
        ) : (
          <>
            <h1>{t("qrCodeScanner.chooseItinerary")}</h1>
            <MyAutocomplete
              onSelect={(e) => setSelectedItinerary(e)}
              label=""
              items={myAutocompleteItems}
            />
            <MyButtonWithBorder
              size="large"
              className={`${styles.unlockButton} ion-text-uppercase`}
              onClick={() => unlockItinerary()}
              disabled={!selectedItinerary}
              color="secondary"
            >
              {t("qrCodeScanner.unlock")}
            </MyButtonWithBorder>
          </>
        )
      ) : (
        <div className={styles.qrCodeScannerContainer}>
          <p className={`${styles.upperDescription} ion-text-uppercase`}>
            {t("qrCodeScanner.invalidToken")}
          </p>
          <MyButtonWithBorder
            onClick={() => reset()}
            size="large"
            color="secondary"
            className={`${styles.scanButton} ion-text-uppercase`}>
            {t("qrCodeScanner.retry")}
          </MyButtonWithBorder>
        </div>
      )}
    </div>
  );

  return (
    <IonPage>
      <IonHeader className="ion-no-border" collapse="condense">
        <Navbar
          nextButtonDisabled={true}
          backButtonDisabled={true}
          toolbarColor="primary"
        />
      </IonHeader>
      <IonContent>
        {isScanning ? (
          <>

          </>
        ) : isTokenValid == null ? (
          preScanContent
        ) : (
          postScanContent
        )}
        <MyModal
          containerClassName={styles.unlockSuccessModal}
          isOpen={consumePatchState === "success"}
        >
          <IonImg
            className={styles.unlockSuccessModalImage}
            src="assets/payment/present.svg"
          ></IonImg>
          <p>{t("qrCodeScanner.itineraryUnlocked")}</p>
          <MyButtonWithBorder
            onClick={() => onSuccessButtonClick()}
            className={`${styles.unlockSuccessModalButton} ion-text-uppercase`}
            color="secondary"
          >
            {t("ui.play")}
          </MyButtonWithBorder>
        </MyModal>
        <MyModal
          containerClassName={styles.unlockSuccessModal}
          isOpen={consumePatchState === "failure"}
        >
          <p>{t("qrCodeScanner.unlockError")}</p>
          <MyButtonWithBorder
            onClick={() => onSuccessButtonClick()}
            size="large"
            className={`${styles.unlockSuccessModalButton} ion-text-uppercase`}
            color="secondary"
          >
            {t("ui.back")}
          </MyButtonWithBorder>
        </MyModal>
      </IonContent>
    </IonPage>
  );
};
export default QrCodeScannerPage;
