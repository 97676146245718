import React from "react";
import {
  UserContextPayload,
  UserStatus,
} from "../models/contexts/user.context.model";

export const UserContext = React.createContext<UserContextPayload>({
  user: {
    status: UserStatus.NOT_LOGGED,
  },
  login: () => new Promise((resolve) => resolve()),
  logout: () => new Promise((resolve) => resolve()),
});
