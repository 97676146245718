import { IonSpinner } from "@ionic/react";
import { ReactNode } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MyWindow from "../my-window/my-window";
import styles from "./window-with-stroke.module.css";

interface WindowWithStrokeProps {
  onWindowTouchStart?: () => void;
  imageUrl?: string;
  className?: string;
  title: string;
  textNode?: ReactNode;
  text?: string;
}

const WindowWithStroke = (props: WindowWithStrokeProps) => {
  return (
    <MyWindow
      className={`${styles.windowWithStrokeWindow} ${props.className}`}
      onTouchStart={() => {
        if (props?.onWindowTouchStart) props.onWindowTouchStart();
      }}
    >
      {props.imageUrl ? (
        <LazyLoadImage
          src={props.imageUrl}
          className={styles.windowWithStrokeImage}
          placeholder={<IonSpinner />}
        />
      ) : null}
      <div className={`${styles.windowWithStrokeTitle} ion-text-center`}>
        <span>{props.title}</span>
      </div>
      <div className={styles.windowWithStrokeText}>
        {props?.textNode ?? props.text}
      </div>
    </MyWindow>
  );
};

export default WindowWithStroke;
