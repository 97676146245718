import axios from "axios";

function makeBearerHeaders(bearer: string) {
  return {
    headers: {
      Authorization: "Bearer " + bearer,
    },
  };
}

interface postInput {
  endpoint: string;
  bearerToken?: string;
  data?: any;
}

export async function post<T>({
  endpoint,
  bearerToken,
  data,
}: postInput): Promise<T> {
  const headers = !!bearerToken ? makeBearerHeaders(bearerToken) : undefined;
  try {
    const res = await axios.post<T>(endpoint, data, headers);
    return res.data;
  } catch (err) {
    throw err;
  }
}

interface patchInput {
  endpoint: string;
  bearerToken?: string;
  data?: any;
}

export async function patch<T>({
  endpoint,
  bearerToken,
  data,
}: patchInput): Promise<T> {
  const headers = !!bearerToken ? makeBearerHeaders(bearerToken) : undefined;
  try {
    const res = await axios.patch<T>(endpoint, data, headers);
    return res.data;
  } catch (err) {
    throw err;
  }
}

interface getInput {
  endpoint: string;
  bearerToken?: string;
}

export async function get<T>({ endpoint, bearerToken }: getInput): Promise<T> {
  const headers = !!bearerToken ? makeBearerHeaders(bearerToken) : undefined;
  try {
    const res = await axios.get<T>(endpoint, headers);
    return res.data;
  } catch (err) {
    throw err;
  }
}
