import { IonButton, IonImg, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { PLAY_STATE } from "../../../models/games/base.models";
import {
  ReorderLettersConfig,
  ReorderLettersGameState,
} from "../../../models/games/reorder-letters.models";
import { Routes } from "../../../routes";
import MyWindow from "../../my-window/my-window";
import EndgameWindow from "../endgame-window/endgame-window";
import CharacterButton from "./character-button";
import styles from "./reorder-letters.module.css";

interface ReorderLettersProps {
  config: ReorderLettersConfig;
  imageUrl: string;
  onWon?: (arg0: number) => void;
  onLost?: (arg0: number) => void;
  onEndgameTouch?: () => void;
}

const ReorderLetters = (props: ReorderLettersProps) => {
  const location = useLocation();

  const [gameState, setGameState] = useState<ReorderLettersGameState>({
    playState: PLAY_STATE.NOT_STARTED,
    score: 0,
  });
  const [placedPiecesIndexes, setPlacedPiecesIndexes] = useState<number[]>([]);
  const [currentOrderedString, setCurrentOrderedString] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (!location.pathname.includes(Routes.WAYPOINT_GAME_MATCH)) return;

    setGameState({ playState: PLAY_STATE.PLAYING, score: 0 });
    initCharacters();
    clearAnswer();
  }, [location]);

  useEffect(() => {
    if (gameState.playState === PLAY_STATE.WON) {
      props.onWon?.(gameState.score);
    } else if (gameState.playState === PLAY_STATE.LOST) {
      props.onLost?.(gameState.score);
    }
  }, [props, gameState]);

  /**
   * When the answer is clicked, the state is updated with the new clicked value
   * @param answerState
   * @param answerIndex
   * @returns
   */

  function initCharacters() {
    let charactersToReorder = props.config.answer.toUpperCase().split("");
    let limit = 0;
    console.log("randomly sorting characters");
    do {
      charactersToReorder.sort(() => Math.random() - 0.5);
      limit++;
    } while (
      charactersToReorder.join("") === props.config.answer.toUpperCase() &&
      limit < 5
    );
    setCurrentOrderedString(charactersToReorder);
  }

  // function areCharactersOrdered(characters: string[]) {
  //   if(characters.length < 2) return true;

  //   const joined = characters.map(el => el.answer).join('');
  //   return joined === props.config.answer.toUpperCase();
  // }

  function onCharacterClick(index: number) {
    setPlacedPiecesIndexes([...placedPiecesIndexes, index]);
  }

  function clearAnswer() {
    setPlacedPiecesIndexes([]);
  }

  function piecesIndexes2String() {
    return placedPiecesIndexes
      .map((index) => currentOrderedString[index])
      .join("");
  }

  function onSendAnswer() {
    if (piecesIndexes2String() === props.config.answer.toUpperCase()) {
      setGameState({
        ...gameState,
        score: props.config.reward,
        playState: PLAY_STATE.WON,
      });
    } else {
      setGameState({ ...gameState, playState: PLAY_STATE.LOST });
    }
  }

  // TODO: backend should check there are no duplicated answers when creating a game
  const charactersHtml = currentOrderedString.map((answer, i) => {
    return (
      <CharacterButton
        onClick={() => onCharacterClick(i)}
        disabled={placedPiecesIndexes.includes(i)}
        fill="clear"
        color="tertiary"
        key={i}
        className={styles.answerItem}
      >
        {answer}
      </CharacterButton>
    );
  });

  return (
    <>
      <LazyLoadImage
        className={styles.upperImage}
        src={props.imageUrl}
        alt="Single choice quiz upper image"
        placeholder={<IonSpinner />}
      ></LazyLoadImage>
      {gameState.playState === PLAY_STATE.PLAYING ||
      gameState.playState === PLAY_STATE.NOT_STARTED ? (
        <MyWindow className={styles.quizWindow}>
          <p className={`${styles.questionText} ion-text-center`}>
            {props.config.question}
          </p>
          <div className={styles.reorderLettersInputFieldContainer}>
            <div className={styles.reorderLettersInputField}>
              {piecesIndexes2String()}
            </div>
            <div className={styles.reorderLettersInputFieldBorder}></div>
          </div>

          {/* <IonReorderGroup onIonItemReorder={(e) => onItemReorder(e)} disabled={false}>
              {
                answersHtml
              }
            </IonReorderGroup> */}
          <div className={styles.characterButtonsContainer}>
            {charactersHtml}
          </div>
          <div className={styles.reorderLettersWindowBottomSection}>
            <IonButton
              disabled={gameState.playState !== PLAY_STATE.PLAYING}
              className={styles.resetButton}
              fill="clear"
              onClick={() => clearAnswer()}
            >
              <IonImg
                className={styles.resetImg}
                src="assets/games/reset.svg"
                alt="Reset"
              ></IonImg>
            </IonButton>
            <IonButton
              disabled={
                gameState.playState !== PLAY_STATE.PLAYING ||
                placedPiecesIndexes.length !== currentOrderedString.length
              }
              className={styles.confirmButton}
              fill="clear"
              onClick={() => onSendAnswer()}
            >
              <IonImg
                className={styles.confirmImg}
                src="assets/games/tick.svg"
                alt="Confirm"
              ></IonImg>
            </IonButton>
          </div>
        </MyWindow>
      ) : (
        <EndgameWindow
          correctAnswer={props.config.answer}
          won={gameState.playState === PLAY_STATE.WON}
          onWindowTouchStart={() => {
            if (props?.onEndgameTouch) props.onEndgameTouch();
          }}
        />
      )}
    </>
  );
};

export default ReorderLetters;
