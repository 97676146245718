import { Control, Controller, FieldValues } from "react-hook-form";
import InputField, { InputFieldProps } from "../input-field/input-field";

export interface InputFieldControllerProps extends Omit<InputFieldProps, 'onChange'> {
  name: string;
  control: Control<FieldValues, any>;
  className?: string;
}

const InputFieldController = (props: InputFieldControllerProps) => {
  const { control, name, ...inputFieldProps } = props;

  return (
    <Controller render={({field}) => {
      return <InputField className={props.className} onChange={field.onChange} {...inputFieldProps}/>
    }}
    name={name}
    control={control}
    />
  );
};
export default InputFieldController;
