import {
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router";
import { v4 } from "uuid";
import MyButtonWithBorder from "../../../components/my-button-with-border/my-button-with-border";
import Navbar from "../../../components/navbar/navbar";
import { ItineraryContext } from "../../../contexts/itinerary.context";
import { Routes } from "../../../routes";
import { TAG_IMAGES_FOLDER } from "../../../variables";
import styles from "./waypoint-intro.module.css";

const WaypointIntroPage = () => {
  const { t } = useTranslation();
  const [isPending, setIsPending] = useState(false);
  const itineraryContext = useContext(ItineraryContext);
  const location = useLocation();
  const [myKey, setMyKey] = useState<string>("");

  useEffect(() => {
    if (location.pathname !== Routes.WAYPOINT_INTRO) return;

    setMyKey(v4());
    if (
      !itineraryContext.currentItinerary ||
      !itineraryContext.currentWaypoint
    ) {
      if (!isPending) setIsPending(true);
    } else if (isPending) setIsPending(false);
  }, [location, itineraryContext]);

  return (
    <IonPage>
      <IonContent color="secondary">
        <div className={styles.waypointIntroContainer}>
          {isPending ? (
            <IonSpinner />
          ) : (
            <>
              <div>
                <LazyLoadImage
                  className={styles.tagImage}
                  src={`${TAG_IMAGES_FOLDER}/${itineraryContext.currentItinerary?.tags[0].logoName}`}
                  alt="Itinerary tag"
                  placeholder={<IonSpinner className="centered" />}
                  style={{ marginTop: "20px" }}
                ></LazyLoadImage>
                <p
                  className={`${styles.upperDescription} ion-text-uppercase ion-text-center`}
                >
                  {t("itinerary.reachNextWaypoint")}:
                </p>
              </div>
              <LazyLoadImage
                className={styles.mapImage}
                src="assets/itinerary/map.svg"
                alt="Map"
                placeholder={<IonSpinner />}
              ></LazyLoadImage>
              <p
                // key={`${itineraryContext.currentItinerary?.id}-${itineraryContext.currentWaypoint?.id}`}
                key={myKey}
                className={`${styles.waypointIndication} ${isPlatform("android") ? styles.waypointIndicationAndroid : ""
                  } ion-text-center zonzo-scrollbar-primary`}
                dangerouslySetInnerHTML={{ __html: itineraryContext.currentWaypoint?.locales?.[0]?.waypointIndication ?? "" }}
              ></p>
              <div style={{ position: 'relative', width: "100%", height: "80px" }}>
                <MyButtonWithBorder
                  onClick={() => itineraryContext.continue()}
                  size="large"
                  color="secondary"
                  className={`${styles.startButton} ion-text-uppercase button-right`}
                >
                  <img src="/arrow-right.svg" height="30" />
                </MyButtonWithBorder>
                {itineraryContext.currentWaypoint?.locales?.[0]?.waypointMapLink && itineraryContext.currentWaypoint?.locales?.[0]?.waypointMapLink !== '-' && (
                  <MyButtonWithBorder
                    onClick={() => window.open(itineraryContext.currentWaypoint?.locales?.[0]
                      ?.waypointMapLink, "_blank")}
                    size="large"
                    color="secondary"
                    className={`${styles.startButton} ion-text-uppercase button-left`}
                  >
                    <img src="/map-pin.svg" height="30" />
                  </MyButtonWithBorder>
                )}
              </div>

              {itineraryContext.currentWaypoint?.locales?.[0]?.waypointSponsorName && itineraryContext.currentWaypoint?.locales?.[0]?.waypointSponsorName !== '-' && (
                <div style={{ position: 'fixed', bottom: '15px', left: '5%', width: '90%', padding: '10px 20px', border: '3px solid #000', borderRadius: '40px' }} >
                  <img src={itineraryContext.currentWaypoint?.locales?.[0]?.waypointSponsorLogo} height="50px" style={{ float: "left", marginRight: "10px", borderRadius: "200px" }} />
                  <i style={{ fontSize: "22px" }}>{itineraryContext.currentWaypoint?.locales?.[0]?.waypointSponsorName.split("-")[0]}</i><br></br><b>{itineraryContext.currentWaypoint?.locales?.[0]?.waypointSponsorName.split("-")[1]}</b>
                  <a style={{ position: 'absolute', right: '22px', top: '15px', fontSize: '35px', border: '0', textDecoration: 'none' }} href={itineraryContext.currentWaypoint?.locales?.[0]?.waypointSponsorLink} target="_blank">
                    <img src="/search.svg" height="30" />
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WaypointIntroPage;
