export enum Locale {
  EN = "en",
  IT = "it",
}

export type LocalizationContextPayload = {
  locale?: Locale;
  defaultLocale: Locale;
  setLocale: (locale: Locale) => void;
};
