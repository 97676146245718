import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Virtuoso } from "react-virtuoso";
import Box from "../../components/box/box";
import BoxedTitle from "../../components/boxed-title/boxed-title";
import MyAutocomplete from "../../components/my-autocomplete/my-autocomplete";
import { LocalizationContext } from "../../contexts/localization.context";
import { UserContext } from "../../contexts/user.context";
import { ItineraryWithName } from "../../models/api/itineraries.models";
import { Score } from "../../models/api/scores.models";
import { UserStatus } from "../../models/contexts/user.context.model";
import { Routes } from "../../routes";
import { get } from "../../services/http-service";
import {
  ENDPOINT_GET_ITINERARIES_NAMES,
  ENDPOINT_GET_SCORES_BY_ITINERARY,
  ENDPOINT_GET_USERS_BEST_SCORES,
} from "../../variables";
import styles from "./leaderboard-page.module.css";
const LeaderboardPage = () => {
  const userContext = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);

  const location = useLocation();
  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState(false);

  const { t } = useTranslation();

  const [itineraries, setItineraries] = useState<ItineraryWithName[]>([]);
  const [selectedItinerary, setSelectedItinerary] =
    useState<ItineraryWithName | null>();
  const [scores, setScores] = useState<Score[] | Score[]>([]);
  const [scoresFetchStatus, setScoresFetchStatus] = useState<
    "not_sent" | "pending" | "success" | "failure"
  >("not_sent");
  const [waitForUserFetch, setWaitForUserFetch] = useState(false);

  useEffect(() => {
    if (location.pathname !== Routes.LEADERBOARD) return;

    if (userContext.user.status === UserStatus.LOGGED) {
      setIsPending(true);
      fetchItinerariesNames().finally(() => setIsPending(false));
    } else {
      setWaitForUserFetch(true);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname !== Routes.LEADERBOARD) return;

    if (waitForUserFetch && userContext.user.status === UserStatus.LOGGED) {
      setIsPending(true);
      fetchItinerariesNames().finally(() => setIsPending(false));
      fetchScores();
    }
  }, [userContext]);

  useEffect(() => {
    if (userContext.user.status === UserStatus.LOGGED) fetchScores();
  }, [selectedItinerary]);

  async function fetchScores() {
    try {
      // setIsPending(true)
      setScoresFetchStatus("pending");
      let res;
      if (!!selectedItinerary) {
        res = await get<Score[]>({
          endpoint: `${ENDPOINT_GET_SCORES_BY_ITINERARY}/${selectedItinerary?.id}`,
          bearerToken: userContext.user.token,
        });
      } else {
        res = await get<Score[]>({
          endpoint: `${ENDPOINT_GET_USERS_BEST_SCORES}?locale=${
            localizationContext.locale ?? localizationContext.defaultLocale
          }`,
          bearerToken: userContext.user.token,
        });
        res = res.sort((a, b) => b.value - a.value);
      }

      setScores(res);
      setScoresFetchStatus("success");
      // setIsPending(false)
    } catch (err) {
      // TODO: handle error case
      setScoresFetchStatus("failure");
    }
  }

  async function fetchItinerariesNames() {
    try {
      const res = await get<ItineraryWithName[]>({
        endpoint: `${ENDPOINT_GET_ITINERARIES_NAMES}`,
        bearerToken: userContext.user.token,
      });

      console.log(res);
      setItineraries(res);
    } catch (err) {
      // TODO: handle error case
      console.log("error while fetching user itineraries: ", err);
    }
  }

  async function onRefresh(event: CustomEvent<RefresherEventDetail>) {
    await fetchItinerariesNames();
    event.detail.complete();
  }

  function onSearchClear() {
    // setScores([]);
    // setSelectedItinerary(null);
  }

  function onSearch() {
    setScores([]);
    setSelectedItinerary(null);
  }

  const myAutocompleteItems = itineraries.map((itinerary) => ({
    id: itinerary.id.toString(),
    name: itinerary.name,
  }));

  const scoresHtml = scores.map((score, index) => {
    return (
      <Box className={styles.itineraryButton} key={index}>
        <div className={styles.itineraryBox}>
          <span className={styles.userIndicator}>{score.user.username}</span>
          <span className={styles.scoreIndicator}>
            {t("leaderboard.score")}: {score.value}
          </span>
          {score?.itinerary && (
            <div className={styles.scoreItinerary}>
              {score?.itinerary?.locales[0]?.name}
            </div>
          )}
        </div>
      </Box>
    );
  });

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton style={{ fontSize: "30px" }}></IonMenuButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.leaderboardIonContent}>
        {!isPending && (
          <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        )}
        <div className={styles.upperSectionContainer}>
          <BoxedTitle className={styles.leaderboardTitle}>
            {t("leaderboard.leaderboard")}
          </BoxedTitle>
          {!isPending && (
            <MyAutocomplete
              className={styles.itineraryAutocomplete}
              onClear={() => onSearchClear()}
              onSearch={(e) => onSearch()}
              onSelect={(e) =>
                setSelectedItinerary({ id: Number(e.id), name: e.name })
              }
              label={t("labels.itinerary").toUpperCase()}
              items={myAutocompleteItems}
            />
          )}
        </div>
        <div className={styles.bottomSectionContainer}>
          {isError || scoresFetchStatus === "failure" ? (
            <div className={styles.errorText}>{t("leaderboard.error")}</div>
          ) : isPending || scoresFetchStatus === "pending" ? (
            <IonSpinner />
          ) : scoresFetchStatus === "success" && scoresHtml.length !== 0 ? (
            <>
              {/* <div className={styles.itinerariesContainer}>{scoresHtml}</div> */}
              <Virtuoso
                className={styles.itinerariesContainer}
                totalCount={scores.length}
                itemContent={(index) => {
                  return (
                    <Box className={styles.itineraryButton} key={index}>
                      <div className={styles.itineraryBox}>
                        <span className={styles.userIndicator}>
                          {scores[index].user.username}
                        </span>
                        <span className={styles.scoreIndicator}>
                          {t("leaderboard.score")}: {scores[index].value}
                        </span>
                        {scores[index]?.itinerary && (
                          <div className={styles.scoreItinerary}>
                            {scores[index]?.itinerary?.locales[0]?.name}
                          </div>
                        )}
                      </div>
                    </Box>
                  );
                }}
              />
            </>
          ) : (
            scoresFetchStatus !== "not_sent" && (
              <div className={styles.noScores}>{t("leaderboard.noScores")}</div>
            )
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LeaderboardPage;
