import React from 'react';
import styles from './my-window.module.css';
interface MyWindowProps extends React.PropsWithChildren<{
  className?: string;
  onTouchStart?: () => void;
}> {}

const MyWindow = (props: MyWindowProps) => {
  return (
    <div onTouchStart={() => {if(props?.onTouchStart) props.onTouchStart()}} className={`${styles.myWindowContainer} ${props.className}`}>
      {props.children}
    </div>
  );
};
export default MyWindow;
