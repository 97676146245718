import {
  IonChip,
  IonContent,
  IonHeader,
  IonImg,
  IonLabel,
  IonPage,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import "@ionic/react/css/ionic-swiper.css";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import "swiper/css";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import Navbar from "../../components/navbar/navbar";
import { SelectionContext } from "../../contexts/selection.context";
import { Routes } from "../../routes";
import { TAG_IMAGES_FOLDER } from "../../variables";
import styles from "./selected-tag-intro-page.module.css";

const SelectedTagIntroPage = () => {
  const selectionContext = useContext(SelectionContext);
  const [isPending, setIsPending] = useState(false);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname !== Routes.SELECTED_TAG_INTRO) return;

    if (!selectionContext.selectedCity || !selectionContext.selectTag) {
      if (!isPending) setIsPending(true);
    } else if (isPending) setIsPending(false);
  }, [location, selectionContext]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar nextButtonDisabled={true} />
      </IonHeader>
      <IonContent color="secondary">
        <div className={styles.chipsContainer}>
          <IonChip className={styles.selectionChip}>
            <IonLabel>
              {selectionContext.selectedCity ??
                t("tagSelection.noCitySelected")}
            </IonLabel>
          </IonChip>
          <IonChip className={styles.selectionChip}>
            <IonLabel>
              {selectionContext.selectedTag?.locales[0]?.name ??
                t("tagSelection.noTagSelected")}
            </IonLabel>
          </IonChip>
        </div>
        <div className={styles.viewContainer}>
          {isPending ? (
            <IonSpinner />
          ) : (
            <>
              <IonImg
                className={styles.tagImage}
                src={`${TAG_IMAGES_FOLDER}/${selectionContext.selectedTag?.logoName}`}
                alt={selectionContext.selectedTag?.locales[0].name}
              ></IonImg>
              <p className={`${styles.tagName} ion-text-uppercase`}>
                {selectionContext.selectedTag?.locales[0].name}
              </p>
              <IonImg
                className={styles.itineraryImage}
                src="assets/selectedTagIntro/spottedLine.svg"
                alt="Itinerary"
              ></IonImg>
              <p
                className={`${styles.tagDescription} ${
                  isPlatform("android") ? styles.tagDescriptionAndroid : ""
                } zonzo-scrollbar-primary`}
              >
                {selectionContext.selectedTag?.locales[0].description}
              </p>
              <MyButtonWithBorder
                routerLink={Routes.ITINERARY_SELECTION}
                size="large"
                color="secondary"
                className={`${styles.chooseButton} ion-text-uppercase`}
              >
                {t("ui.choose")}
              </MyButtonWithBorder>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
export default SelectedTagIntroPage;
