// import { GoogleAnalytics } from "@awesome-cordova-plugins/google-analytics";
import "@ionic/react/css/ionic-swiper.css";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
// import 'swiper/swiper.min.css';
import { useIonToast } from "@ionic/react";
import "swiper/css";
import { ItineraryContext } from "./contexts/itinerary.context";
import { LocalizationContext } from "./contexts/localization.context";
import { SelectionContext } from "./contexts/selection.context";
import { UserContext } from "./contexts/user.context";
import { ValidateTokenOutput } from "./models/api/auth.models";
import { GAME_TYPE } from "./models/api/games.models";
import { Itinerary } from "./models/api/itineraries.models";
import { TagFull } from "./models/api/tags.models";
import { WaypointFull } from "./models/api/waypoints.models";
import {
  ItineraryContextPayload,
  WaypointPhase,
} from "./models/contexts/itinerary.context.models";
import {
  Locale,
  LocalizationContextPayload,
} from "./models/contexts/localization.context.models";
import { SelectionContextPayload } from "./models/contexts/selection.context.models";
import {
  LoginPayload,
  User,
  UserContextPayload,
  UserStatus,
} from "./models/contexts/user.context.model";
import { Routes } from "./routes";
import { get, post } from "./services/http-service";
import * as storage from "./storage";
import {
  AUTH_TOKEN_KEY,
  CURRENT_GAME_INDEX_DEFAULT,
  CURRENT_WAYPOINT_INDEX_DEFAULT,
  ENDPOINT_GET_ITINERARY_FULL,
  ENDPOINT_GET_WAYPOINT_FULL,
  ENDPOINT_VALIDATE_TOKEN,
  LANG_KEY,
  SAVED_ITINERARY_KEY,
} from "./variables";

const Store = ({ children }: { children: ReactNode }) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const [present] = useIonToast();
  const [user, setUser] = useState<User>({ status: UserStatus.NOT_LOGGED });
  // const location = useLocation();
  const [locale, setLocale] = useState<Locale>();
  const [selectedCity, setSelectedCity] = useState<string>("Milano");
  const [selectedTag, setSelectedTag] = useState<TagFull>();
  const [itineraryToPurchase, setItineraryToPurchase] = useState<Itinerary>();
  const [currentItinerary, setCurrentItinerary] = useState<Itinerary>();
  const [currentItineraryToken, setCurrentItineraryToken] = useState<string>();
  const [currentWaypointIndex, setCurrentWaypointIndex] = useState<number>(
    CURRENT_WAYPOINT_INDEX_DEFAULT
  );
  const [currentWaypoint, setCurrentWaypoint] = useState<WaypointFull>();
  const [currentWaypointPhase, setCurrentWaypointPhase] =
    useState<WaypointPhase>(WaypointPhase.INTRO);
  const [currentGameIndex, setCurrentGameIndex] = useState<number>(
    CURRENT_GAME_INDEX_DEFAULT
  );
  const [currentScore, setCurrentScore] = useState<number>(0);

  const showCheckpointToast = () => {
    present({
      message: t("ui.checkpointReached"),
      duration: 2500,
      position: "top",
      color: "primary",
      cssClass: "ion-text-center",
    });
  };

  const getCurrentPosition = async () => {
    // check if there is a stored itinerary
    const storedItineraryPosition = await storage.get(SAVED_ITINERARY_KEY);
    if (!!storedItineraryPosition && !!currentItinerary) {
      // check if stored itinerary corresponds to selected itinerary
      console.log(currentItinerary);
      if (
        currentItinerary.id.toString() ===
        storedItineraryPosition?.itineraryId?.toString()
      ) {
        return storedItineraryPosition;
      } else {
        // delete stored itinerary if selected one is different
        await storage.set(SAVED_ITINERARY_KEY, undefined);
      }
    }

    return null;
  };

  const localizationContextValue: LocalizationContextPayload = {
    locale: locale,
    defaultLocale: Locale.IT,
    setLocale: async (newLocale: Locale) => {
      setLocale(newLocale);
    },
  };

  const userContextValue: UserContextPayload = {
    user,
    login: async (payload: LoginPayload) => {
      await storage.set(AUTH_TOKEN_KEY, payload.token);
      setUser({
        status: UserStatus.LOGGED,
        id: payload.id,
        email: payload.email,
        username: payload.username,
        token: payload.token,
        wallet: payload.wallet
      });
    },
    logout: async () => {
      localStorage.removeItem('logged')
      await storage.set(AUTH_TOKEN_KEY, undefined);
      setUser({ status: UserStatus.NOT_LOGGED });
      history.push(Routes.ACCESS);
    },
  };
  const selectionContextValue: SelectionContextPayload = {
    selectedCity: selectedCity,
    selectedTag: selectedTag,
    selectedItinerary: itineraryToPurchase,
    selectTag: (payload: TagFull) => setSelectedTag(payload),
    selectCity: (payload: string) => setSelectedCity(payload.toLowerCase()),
    selectItinerary: (payload: Itinerary) => setItineraryToPurchase(payload),
  };

  const itineraryContextValue: ItineraryContextPayload = {
    currentItinerary: currentItinerary,
    itineraryToken: currentItineraryToken,
    currentWaypoint: currentWaypoint,
    waypointPhase: currentWaypointPhase,
    gameIndex: currentGameIndex,
    currentScore: currentScore,
    continue: () => {
      console.log("continuing...");
      switch (currentWaypointPhase) {
        case WaypointPhase.INTRO:
          setCurrentGameIndex(0);
          setCurrentWaypointPhase(WaypointPhase.GAME);
          if (currentWaypoint?.games[0].type === GAME_TYPE.RIDDLE)
            history.push(`${Routes.WAYPOINT_RIDDLE}/0`);
          else history.push(`${Routes.WAYPOINT_GAME}/0`);
          break;
        case WaypointPhase.GAME:
          if (
            !!currentWaypoint &&
            currentGameIndex < currentWaypoint.games.length - 1
          ) {
            const newIndex = (currentGameIndex ?? 0) + 1;
            setCurrentGameIndex(newIndex);

            // storage.set(SAVED_ITINERARY_KEY, {
            //   itineraryId: currentItinerary?.id,
            //   waypointId: currentWaypointIndex,
            //   waypointPhase: currentWaypointPhase,
            //   gameIndex: newIndex,
            //   score: currentScore,
            // });

            if (currentWaypoint?.games[newIndex].type === GAME_TYPE.RIDDLE)
              history.push(`${Routes.WAYPOINT_RIDDLE}/${newIndex}`);
            else history.push(`${Routes.WAYPOINT_GAME}/${newIndex}`);
          } else {
            setCurrentWaypointPhase(WaypointPhase.ANECDOTE);

            storage.set(SAVED_ITINERARY_KEY, {
              itineraryId: currentItinerary?.id,
              waypointId: currentWaypointIndex + 1,
              waypointPhase: WaypointPhase.INTRO,
              gameIndex: 0,
              score: currentScore,
            });
            showCheckpointToast();

            // if (!!currentWaypoint) {
            //   storage.set(SAVED_ITINERARY_KEY, {
            //     itineraryId: currentItinerary?.id,
            //     waypointId: currentWaypointIndex,
            //     waypointPhase: WaypointPhase.ANECDOTE,
            //     gameIndex: currentGameIndex,
            //     score: currentScore,
            //   });
            // }

            history.push(`${Routes.WAYPOINT_ANECDOTE}`);
          }
          break;
        case WaypointPhase.ANECDOTE:
          if (
            !!currentItinerary &&
            currentWaypointIndex < currentItinerary?.waypoints.length - 1
          ) {
            // storage.set(SAVED_ITINERARY_KEY, {
            //   itineraryId: currentItinerary?.id,
            //   waypointId: currentWaypointIndex + 1,
            //   waypointPhase: WaypointPhase.INTRO,
            //   gameIndex: 0,
            //   score: currentScore,
            // });
            // showCheckpointToast();
            setCurrentWaypointIndex(currentWaypointIndex + 1);
          } else {
            //TODO: ON ITINERARY END
            setCurrentWaypointPhase(WaypointPhase.RECAP);
            history.push(`${Routes.WAYPOINT_RECAP}`);
          }
          break;
        default:
          //TODO: ON ITINERARY END
          storage.set(SAVED_ITINERARY_KEY, undefined);
          history.push(Routes.ITINERARY_COMPLETE);
          // AnalyticsPlugin.sendFacebookEvent({
          //   value: "itinerary_completed",
          // });
          // Facebook.logEvent("itinerary_completed", {});
          break;
      }
    },
    startItinerary: async (itineraryToken) => {
      // const itinerary = await patch<Itinerary>({endpoint: ENDPOINT_CONSUME_ITINERARY_TOKEN, bearerToken: user.token, data: {token: itineraryToken}});
      // setCurrentItinerary(itinerary);

      async function fetchItinerary() {
        try {
          const res = await get<Itinerary>({
            endpoint: `${ENDPOINT_GET_ITINERARY_FULL}/${itineraryToken.itinerary.id
              }?locale=${locale ?? localizationContextValue.defaultLocale}`,
            bearerToken: user.token,
          });
          const sorted = res;
          sorted.waypoints = sorted.waypoints.sort(
            (a, b) => a.itineraryOrder - b.itineraryOrder
          );
          setCurrentItinerary(sorted);
          setCurrentItineraryToken(itineraryToken.value);
        } catch (err: any) {
          // TODO: handle case
        }
      }
      setCurrentWaypointIndex(CURRENT_WAYPOINT_INDEX_DEFAULT);
      setCurrentWaypoint(undefined);
      setCurrentWaypointPhase(WaypointPhase.INTRO);
      setCurrentGameIndex(0);
      setCurrentScore(0);
      await fetchItinerary();
      history.push(Routes.WAYPOINT_INTRO);

      try {
        // AnalyticsPlugin.sendFacebookEvent({
        //   value: "itinerary_started",
        // });
        // Facebook.logEvent("itinerary_started", {});
      } catch (err) {
        console.log(err);
      }
    },
    endItinerary: async () => {
      setCurrentItineraryToken(undefined);
    },
    addScore: (score: number) => {
      setCurrentScore(currentScore + score);
    },
  };

  // create the store and checks if an authentication token exists
  useEffect(() => {
    async function init() {
      await storage.createStorage();
      const token = await storage.get(AUTH_TOKEN_KEY);
      const lang = await storage.get(LANG_KEY);
      if (!!token) {
        try {
          const validationRes = await post<ValidateTokenOutput>({
            endpoint: ENDPOINT_VALIDATE_TOKEN,
            bearerToken: token,
          });

          userContextValue.login({
            id: validationRes.id,
            email: validationRes.email,
            username: validationRes.username,
            wallet: validationRes.wallet,
            token,
          });
          // console.log(validationRes);
          // history.push(Routes.CITY_SELECTION);
          // setCurrentItinerary({
          //     city: 'Milano',
          //     cost: 30,
          //     id: 6,
          //     waypoints: [{id: 4, itineraryOrder: 1}],
          //     locales: [
          //         {
          //         name: 'Itinerario 1',
          //         description: 'Descrizione itinerario 1',
          //         locale: Locale.IT,
          //         isDefault: true
          //         }
          //     ],
          //     tags: [
          //         {
          //         order: 0,
          //         logoName: 'http://placeimg.com/640/480'
          //         }
          //     ]
          // })
        } catch (err: any) {
          // TODO: handle case
          storage.set(AUTH_TOKEN_KEY, undefined);
          // if (location.pathname !== Routes.LOGIN) {
          //     history.push(Routes.LOGIN);
          // }
        }
      }
      if (!!lang) {
        setLocale(lang);
      }
    }

    init();
  }, []);

  // useEffect(() => {
  //   document
  //     .querySelectorAll(".backdrop-sensitive")
  //     .forEach((el) => el.classList.toggle("backdrop-active", false));
  // }, [location]);

  useEffect(() => {
    async function updateLocale() {
      await i18n.changeLanguage(locale);
      await storage.set(LANG_KEY, locale);
    }

    if (!!locale) updateLocale();
  }, [locale]);

  useEffect(() => {
    async function setPosition() {
      if (!!currentItinerary) {
        console.log("setting current itinerary: ", currentItinerary);

        let newWaypointIndex = 0;
        const storedItineraryPosition = await getCurrentPosition();
        if (!!storedItineraryPosition) {
          newWaypointIndex = Number(storedItineraryPosition.waypointId);
          setCurrentScore(Number(storedItineraryPosition.score));
        }

        setCurrentWaypointIndex(newWaypointIndex);
      }
    }

    setPosition();
  }, [currentItinerary]);

  useEffect(() => {
    async function fetchCurrentWaypoint() {
      // TODO: check if the server returns ordered waypoints
      const waypointId = !!currentItinerary?.waypoints
        ? currentItinerary?.waypoints[currentWaypointIndex].id
        : null;
      try {
        // TODO: if(!user.token) THROW ERROR
        if (!!user.token && !!waypointId) {
          const res = await get<WaypointFull>({
            endpoint: `${ENDPOINT_GET_WAYPOINT_FULL}/${waypointId}?locale=${locale ?? localizationContextValue.defaultLocale
              }`,
            bearerToken: user.token,
          });
          const sorted = res;
          sorted.games = sorted.games.sort((a, b) => a.order - b.order);
          setCurrentWaypoint(sorted);
        }
      } catch (err: any) {
        // TODO: handle case
      }
    }

    setCurrentWaypointPhase(WaypointPhase.INTRO);
    if (!!currentItinerary && currentWaypointIndex >= 0) fetchCurrentWaypoint();
  }, [currentWaypointIndex]);

  useEffect(() => {
    if (!!currentWaypoint) {
      history.push(Routes.WAYPOINT_INTRO);
    }
  }, [currentWaypoint]);

  // function reset() {
  //     setCurrentItinerary(undefined);
  //     setCurrentItineraryToken(undefined);
  //     setCurrentWaypoint(undefined);
  //     setCurrentWaypointIndex(CURRENT_WAYPOINT_INDEX_DEFAULT);
  //     setCurrentWaypointPhase(WaypointPhase.INTRO);
  //     setCurrentScore(0);
  //     setCurrentGameIndex(CURRENT_GAME_INDEX_DEFAULT);
  // }

  return (
    <LocalizationContext.Provider value={localizationContextValue}>
      <UserContext.Provider value={userContextValue}>
        <SelectionContext.Provider value={selectionContextValue}>
          <ItineraryContext.Provider value={itineraryContextValue}>
            {children}
          </ItineraryContext.Provider>
        </SelectionContext.Provider>
      </UserContext.Provider>
    </LocalizationContext.Provider>
  );
};
export default Store;
