import {
  IonChip,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import "@ionic/react/css/ionic-swiper.css";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory, useLocation } from "react-router";
import "swiper/css";
import MyButtonWithBorder from "../../components/my-button-with-border/my-button-with-border";
import Navbar from "../../components/navbar/navbar";
import Slider from "../../components/slider/slider";
import { LocalizationContext } from "../../contexts/localization.context";
import { SelectionContext } from "../../contexts/selection.context";
import { UserContext } from "../../contexts/user.context";
import { GetTagsByCityOutput, TagFull } from "../../models/api/tags.models";
import { Routes } from "../../routes";
import { get } from "../../services/http-service";
import { ENDPOINT_GET_TAGS_BY_CITY, TAG_IMAGES_FOLDER } from "../../variables";
import styles from "./tag-selection-page.module.css";

const TagSelectionPage = () => {
  const userContext = useContext(UserContext);
  const selectionContext = useContext(SelectionContext);
  const localizationContext = useContext(LocalizationContext);
  const [tags, setTags] = useState<TagFull[]>([]);
  const { t } = useTranslation();
  const history = useHistory();
  const [isPending, setIsPending] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== Routes.TAG_SELECTION) return;

    async function fetchTags() {
      try {
        setIsPending(true);
        const res = await get<GetTagsByCityOutput>({
          endpoint: `${ENDPOINT_GET_TAGS_BY_CITY}/${
            selectionContext.selectedCity
          }?locale=${
            localizationContext.locale ?? localizationContext.defaultLocale
          }`,
        });
        setTags(res);
        setIsPending(false);
      } catch (err) {
        // TODO: handle error case
        setIsPending(false);
      }
    }

    fetchTags();
  }, [location]);

  function discoverMoreButtonClick(tag: TagFull) {
    selectionContext.selectTag(tag);
    history.push(Routes.SELECTED_TAG_INTRO);
  }

  const tagsSlides = tags.map((tag) => (
    <div className={styles.viewContainer}>
      <p className={styles.tagName}>{tag.locales[0].name}</p>
      <LazyLoadImage
        className={styles.tagSlideImage}
        src={`${TAG_IMAGES_FOLDER}/${tag.logoName}`}
        alt={tag.locales[0].name}
        placeholder={<IonSpinner />}
      ></LazyLoadImage>
      <MyButtonWithBorder
        onClick={() => discoverMoreButtonClick(tag)}
        size="large"
        color="secondary"
        className={`${styles.discoverMoreButton} ion-text-uppercase`}
      >
        {t("ui.discoverMore")}
      </MyButtonWithBorder>
    </div>
  ));

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <Navbar nextButtonDisabled={true} />
      </IonHeader>
      <IonContent color="secondary">
        <div className={styles.chipsContainer}>
          <IonChip className={styles.selectionChip}>
            <IonLabel>
              {selectionContext.selectedCity ??
                t("tagSelection.noCitySelected")}
            </IonLabel>
          </IonChip>
        </div>
        {isPending ? (
          <div className={styles.centeredContainer}>
            <IonSpinner />
          </div>
        ) : tagsSlides.length === 0 ? (
          <div className={styles.centeredContainer}>
            <p className={styles.noTagsMessage}>
              {t("tagSelection.noTagsAvailable")}
            </p>
          </div>
        ) : (
          <Slider
            defaultValue={1}
            navigation={{ nextEl: ".next", prevEl: ".back" }}
            pagination={{
              bulletClass: `${styles.sliderBullet} swiper-pagination-bullet`,
              bulletActiveClass: `${styles.sliderBulletActive} swiper-pagination-bullet-active`,
            }}
            className={styles.sliderContainer}
            slides={tagsSlides}
          ></Slider>
        )}
      </IonContent>
    </IonPage>
  );
};
export default TagSelectionPage;
