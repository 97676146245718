// URLS
// export const API_DOMAIN = "http://localhost:3000";
export const API_NFT_DOMAIN = "https://gateway.niftyz.tools";
export const API_DOMAIN = "https://api.zonzoapp.com";
export const ENDPOINT_SIGNUP = `${API_DOMAIN}/auth/signup`;
export const ENDPOINT_LOGIN = `${API_DOMAIN}/auth/login`;
export const ENDPOINT_LOGIN_SOCIAL = `${API_DOMAIN}/auth/social-login`;
export const ENDPOINT_DELETE_ACCOUNT = `${API_DOMAIN}/auth/delete-account`;
export const ENDPOINT_REQUEST_NEW_PASSWORD = `${API_DOMAIN}/auth/request-new-password`;
export const ENDPOINT_VALIDATE_TOKEN = `${API_DOMAIN}/auth/validate-token`;
export const ENDPOINT_GET_TAGS_BY_CITY = `${API_DOMAIN}/tags/by-city`;
export const ENDPOINT_GET_AVAILABLE_CITIES = `${API_DOMAIN}/itineraries/available-cities`;
export const ENDPOINT_GET_PUBLISHED_ITINERARIES = `${API_DOMAIN}/itineraries/published`;
export const ENDPOINT_GET_ITINERARIES_NAMES = `${API_DOMAIN}/itineraries/names`;
export const ENDPOINT_GET_ITINERARIES_FILTERED = `${API_DOMAIN}/itineraries/getFiltered`;
export const ENDPOINT_GET_ITINERARY_FULL = `${API_DOMAIN}/itineraries/get-full`;
export const ENDPOINT_GET_ITINERARIES_ALL = `${API_DOMAIN}/itineraries/all`;
export const ENDPOINT_GET_WAYPOINT_FULL = `${API_DOMAIN}/waypoints/get-full`;
export const ENDPOINT_CREATE_PAYMENT_INTENT = `${API_DOMAIN}/payments/create-payment-intent`;
export const ENDPOINT_POST_CREATE_FREE_ITINERARY_TOKEN = `${API_DOMAIN}/itinerary-token/create-free`;
export const ENDPOINT_GET_ITINERARY_TOKENS_WITH_ITINERARY = `${API_DOMAIN}/itinerary-token/unused-tokens-with-itinerary`;
export const ENDPOINT_GET_BALANCE = `${API_DOMAIN}/wallet/balance`;
export const ENDPOINT_GET_CHECK_RECEIPT = `${API_DOMAIN}/wallet/receipt`;
export const ENDPOINT_CONSUME_ITINERARY_TOKEN = `${API_DOMAIN}/itinerary-token/consume`;
export const ENDPOINT_GET_VALIDATE_DISCOUNT_TOKEN = `${API_DOMAIN}/discountToken/checkValidity`;
export const ENDPOINT_PATCH_CONSUME_DISCOUNT_TOKEN = `${API_DOMAIN}/discountToken/consume`;
export const ENDPOINT_GET_SCORES_BY_ITINERARY = `${API_DOMAIN}/scores/itinerary`;
export const ENDPOINT_GET_USERS_BEST_SCORES = `${API_DOMAIN}/scores/users-best`;
export const ENDPOINT_POST_CREATE_SCORE_ENTRY = `${API_DOMAIN}/scores/create`;
export const ENDPOINT_POST_MINT_REQUEST_CREATE = `${API_DOMAIN}/mint-request/create`;
export const ENDPOINT_POST_MINT_REQUEST_MINT = `${API_DOMAIN}/mint-request/mint`;
export const ENDPOINT_POST_MINT_REQUEST_TRANSFER = `${API_DOMAIN}/mint-request/transfer`;
export const ENDPOINT_GET_MERCHANT_BY_ADDRESS = `${API_DOMAIN}/merchant/get-by-address`;
export const ENDPOINT_POST_WALLET_TRANSFER = `${API_DOMAIN}/wallet/transfer-to`;
export const ENDPOINT_USER_NFTS = `${API_NFT_DOMAIN}/open/collected`;
export const ENDPOINT_ZONZO_ADDRESS = `${API_DOMAIN}/owner-address`;
export const ENDPOINT_IAP = `${API_DOMAIN}/iap`;

export const TAG_IMAGES_FOLDER = `${API_DOMAIN}/tagImages`;
export const GAME_IMAGES_FOLDER = `${API_DOMAIN}/gameImages`;

// STORAGE
export const AUTH_TOKEN_KEY = "authToken";
export const LANG_KEY = "lang";
export const SAVED_ITINERARY_KEY = "savedItinerary";

// DEFAULTS
export const CURRENT_WAYPOINT_INDEX_DEFAULT = -1;
export const CURRENT_GAME_INDEX_DEFAULT = 0;
