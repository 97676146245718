import { IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import MyButtonWithBorder from "../../../components/my-button-with-border/my-button-with-border";
import MyModal from "../../../components/my-modal/my-modal";
import { UserContext } from "../../../contexts/user.context";
import { DeleteOutput } from "../../../models/api/auth.models";
import { Routes } from "../../../routes";
import { post } from "../../../services/http-service";
import { ENDPOINT_DELETE_ACCOUNT } from "../../../variables";
import styles from "./delete-account-page.module.css";

const DeleteAccountPage = () => {
  // hooks
  const userContext = useContext(UserContext);

  const location = useLocation();
  const { t } = useTranslation();
  const [deleteRequestStatus, setDeleteRequestStatus] = useState("not_sent");
  const [deleteRequestErrorCode, setDeleteRequestErrorCode] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (location.pathname !== Routes.DELETE_ACCOUNT) return;

    setDeleteRequestStatus("not_sent");
    setDeleteRequestErrorCode(undefined);
  }, [location]);

  const submitDelete = async (e: any) => {
    try {
      setDeleteRequestStatus("pending");
      const res = await post<DeleteOutput>({
        endpoint: ENDPOINT_DELETE_ACCOUNT,
        bearerToken: userContext.user.token,
      });
      if (res.error) {
        setDeleteRequestStatus("failure");
        setDeleteRequestErrorCode(res.code);
      } else {
        setDeleteRequestStatus("success");
        await userContext.logout();
      }
    } catch (err) {
      setDeleteRequestStatus("failure");
      setDeleteRequestErrorCode("generic");
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary"></IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={`${styles.deleteAccountContainer} ion-padding`}>
          <p className={styles.deleteAccountConfirm}>
            {t("privacy.confirmDeletion")}
          </p>
          <MyButtonWithBorder
            disabled={deleteRequestStatus !== "not_sent"}
            color="secondary"
            onClick={submitDelete}
          >
            {t("privacy.delete")}
          </MyButtonWithBorder>
        </div>
      </IonContent>
      <MyModal
        containerClassName={styles.deleteEndModal}
        isOpen={deleteRequestStatus === "failure"}
        onCloseClick={() => {
          setDeleteRequestStatus("not_sent");
          setDeleteRequestErrorCode(undefined);
        }}
      >
        <p>{t(`errors.${deleteRequestErrorCode}`)}</p>
      </MyModal>
    </IonPage>
  );
};

export default DeleteAccountPage;
