import React from "react";
import {
  Locale,
  LocalizationContextPayload,
} from "../models/contexts/localization.context.models";

export const LocalizationContext =
  React.createContext<LocalizationContextPayload>({
    defaultLocale: Locale.IT,
    setLocale: () => {},
  });
