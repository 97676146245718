import { Storage } from "@ionic/storage";

let storageSingleton: Storage;

export async function createStorage() {
  storageSingleton = new Storage();
  await storageSingleton.create();
}

export async function get(key: string) {
  return await storageSingleton.get(key);
}

export async function set(key: string, value: any) {
  return await storageSingleton.set(key, value);
}
