import {
  IonIcon,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
} from "@ionic/react";
import { ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import MyModal from "../../components/my-modal/my-modal";
import { LocalizationContext } from "../../contexts/localization.context";
import { UserContext } from "../../contexts/user.context";
import { UserStatus } from "../../models/contexts/user.context.model";
import { Routes } from "../../routes";
import MyButtonWithBorder from "../my-button-with-border/my-button-with-border";
import styles from "./tabs.module.css";

const Tabs = ({ children }: { children: ReactNode }) => {
  const userContext = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);
  const history = useHistory();
  const location = useLocation();
  const [leaveRequest, setLeaveRequest] = useState("");
  const { t } = useTranslation();

  const tabButtons: {
    tabName: string;
    route: Routes;
    iconPath: string;
    isVisible: boolean;
  }[] = [
    {
      tabName: "city selection",
      route: Routes.CITY_SELECTION,
      iconPath: "assets/tabBar/homeIcon.svg",
      isVisible: true,
    },
    {
      tabName: "login",
      route: Routes.ACCESS,
      iconPath: "assets/tabBar/accessIcon.svg",
      isVisible: userContext.user.status === UserStatus.NOT_LOGGED,
    },
    {
      tabName: "user itineraries",
      route: Routes.USER_ITINERARIES,
      iconPath: "assets/tabBar/suitcaseIcon.svg",
      isVisible: userContext.user.status === UserStatus.LOGGED,
    },
    {
      tabName: "leaderboard",
      route: Routes.LEADERBOARD,
      iconPath: "assets/tabBar/starIcon.svg",
      isVisible: userContext.user.status === UserStatus.LOGGED,
    },
    {
      tabName: "qr code scanner",
      route: Routes.QR_CODE_SCANNER,
      iconPath: "assets/tabBar/qrcodeIcon.svg",
      isVisible:
        userContext.user.status === UserStatus.LOGGED
    },
    {
      tabName: "wallet",
      route: Routes.NFTS,
      iconPath: "assets/tabBar/walletIcon.svg",
      isVisible: userContext.user.status === UserStatus.LOGGED,
    },
  ];

  const selectTab = (route: string) => {
    if (location.pathname.includes("/waypoint/")) {
      setLeaveRequest(route);
    } else {
      history.push(route);
    }
  };
  const confirmLeave = () => {
    history.push(leaveRequest);
    setLeaveRequest("");
  };

  function buildTabButtons() {
    return tabButtons
      .filter((el) => el.isVisible)
      .map((tabButton, i) => {
        return (
          <IonTabButton
            key={i}
            tab={tabButton.tabName}
            className="backdrop-sensitive"
          >
            <div className={styles.tabButtonContent}>
              <IonIcon
                color="dark"
                className={styles.tabButtonIcon}
                onClick={() => selectTab(tabButton.route)}
                icon={tabButton.iconPath}
              ></IonIcon>
            </div>
          </IonTabButton>
        );
      });
  }

  return (
    <>
      <IonTabs>
        {children}
        <IonTabBar
          slot="bottom"
          hidden={
            !localizationContext.locale &&
            location.pathname === Routes.LANGUAGE_SELECTION
          }
          color="primary"
        >
          {/* <IonTabBar slot="bottom" hidden={userContext.user.status !== UserStatus.LOGGED} color='primary'> */}
          {buildTabButtons()}
        </IonTabBar>
      </IonTabs>
      <MyModal
        isOpen={leaveRequest !== ""}
        avoidBackdropSensitive={true}
        containerClassName={styles.confirmLeaveModal}
        onCloseClick={() => setLeaveRequest("")}
        onBackgroundClick={() => setLeaveRequest("")}
      >
        <p>{t("ui.pageLeaveConfirm")}</p>
        <div className={styles.confirmLeaveModalFooter}>
          <MyButtonWithBorder
            className={`${styles.confirmLeaveModalButton} ion-text-uppercase`}
            color="secondary"
            onClick={() => setLeaveRequest("")}
          >
            {t("ui.no")}
          </MyButtonWithBorder>
          <MyButtonWithBorder
            className={`${styles.confirmLeaveModalButton} ion-text-uppercase`}
            color="secondary"
            onClick={() => confirmLeave()}
          >
            {t("ui.yes")}
          </MyButtonWithBorder>
        </div>
      </MyModal>
    </>
  );
};
export default Tabs;
