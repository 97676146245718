export enum UserStatus {
  LOGGED = "logged",
  NOT_LOGGED = "not_logged",
  FETCHING = "fetching",
}

export type User = {
  status: UserStatus;
  email?: string;
  id?: number;
  username?: string;
  token?: string;
  wallet?: string;
};

export type LoginPayload = {
  id: number;
  email: string;
  username: string;
  token: string;
  wallet: string
};

export type UserContextPayload = {
  user: User;
  login: (payload: LoginPayload) => Promise<void>;
  logout: () => Promise<void>;
};
