// ================================================================================================================
// =================== TO SEE THE ORIGINAL "wallet-page" GO TO ../../pages/wallet/draft/ ==========================
// ================================================================================================================

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  IonImg
} from "@ionic/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import UserNft from "../../components/nfts/UserNft";
import { UserContext } from "../../contexts/user.context";
import { UserStatus } from "../../models/contexts/user.context.model";
import { Routes } from "../../routes";
import { ENDPOINT_USER_NFTS, ENDPOINT_ZONZO_ADDRESS } from "../../variables";
import styles from "./nft-page.module.css";

import walletIcon from "./wallet.svg";
import { Clipboard } from '@capacitor/clipboard';
interface NftsProps {
  selectedNftIndex?: number;
}

interface ListNft {
  amount: number;
  metadata: {
    title: string;
    description: string;
    cover: string;
    creator: string;
  };
  metadata_hash: string;
  nft_owner: string;
  transfereable: boolean;
}

const NFTsPage: React.FC<NftsProps> = ({ selectedNftIndex = null }) => {
  // ==================================================================================================================================== TODO: NEW
  const [ownerAddress, setOwnerAddress] = useState("");
  const [listNft, setUserNfts] = useState<ListNft[]>([]);
  const [currentSelectedNftIndex, setCurrentSelectedNftIndex] = useState<
    number | null
  >(selectedNftIndex);

  const [showModalAction, setShowModalAction] = useState(false);

  const toggleActionModal = () => {
    setShowModalAction(!showModalAction);
  };
  // ==================================================================================================================================== END | NEW

  const userContext = useContext(UserContext);
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const [isPending, setIsPending] = useState(false);

  const [waitForUserFetch, setWaitForUserFetch] = useState(false);
  const [walletAddressCopied, setWalletAddressCopied] = useState(false);

  useEffect(() => {
    if (location.pathname !== Routes.NFTS) return;

    if (userContext.user.status === UserStatus.LOGGED) {
      setIsPending(true);
      loadState();
    } else {
      setWaitForUserFetch(true);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname !== Routes.NFTS) return;

    if (waitForUserFetch && userContext.user.status === UserStatus.LOGGED) {
      setIsPending(true);
      loadState();
    }
  }, [userContext]);

  async function loadState() {
    const responseAddress = await fetchAddressOwner();
    if (responseAddress !== "") {
      await fetchNfts(responseAddress);
    }
    setIsPending(false);
  }

  async function fetchAddressOwner(): Promise<string> {
    try {
      const url = `${ENDPOINT_ZONZO_ADDRESS}`;
      const response = await axios.get(url);
      console.log("Response Owner Address", response.data.address);
      setOwnerAddress(response.data.address);
      console.log("owner address", ownerAddress);
      return response.data.address;
    } catch (err) {
      setIsError(true);
      console.log("Error:", err);
      return "";
    }
  }

  async function fetchNfts(filterAddress: string) {
    try {
      console.log(userContext.user);
      const url = `${ENDPOINT_USER_NFTS}/`;
      const data = {
        limit: 999,
        receiver: userContext.user.wallet,
      };
      const response = await axios.post(url, data);
      let userNfts = response.data.tokens.filter(
        (nft: ListNft) => nft.nft_owner === filterAddress
      );
      setUserNfts(userNfts);
      console.log(response.data);
      console.log(`POST ${url} - Request:`, data, "- Response:", userNfts);
    } catch (err) {
      setIsError(true);
      console.log("Error:", err);
    }
  }

  async function onRefresh(event: CustomEvent<RefresherEventDetail>) {
    await loadState();
    event.detail.complete();
  }

  const copyWalletAddress = async () => {
    if (userContext?.user?.wallet) {
      await Clipboard.write({
        string: userContext.user.wallet
      });
    }
    setWalletAddressCopied(true)
    setTimeout(() => {
      setWalletAddressCopied(false)
    }, 2000)
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border backdrop-sensitive">
        <IonToolbar color="secondary" mode="ios">
          <IonTitle className={`${styles.walletTitle} ion-text-uppercase`}>
            NFTS
            <div>
              {userContext?.user?.wallet &&
                <span onClick={copyWalletAddress} className={styles.walletAddress}>{t("wallet.address")+userContext.user.wallet.slice(0, 6) + '...' + userContext.user.wallet.slice(-6)}
                  <IonImg
                    style={{ width: "12px", height: "12px", margin: "1px 0 0 2px" }}
                    className={styles.confirmImg}
                    src="assets/copy-outline.svg"
                    alt="Confirm"
                  ></IonImg></span>
              }
            </div>
          </IonTitle>{" "}
          <IonButtons slot="end">
            <IonButton
              routerLink={Routes.WALLET}
              color="tertiary"
              className={`${styles.walletNavbarBtn} ion-text-uppercase`}
            >
              WALLET
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <span className={(walletAddressCopied) ? styles.copiedAlert + ' ' + styles.copiedAlertVisible : styles.copiedAlert}>{t("ui.copied")}</span>
      </IonHeader>
      <IonContent fullscreen color="secondary">
        {!isPending && (
          <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        )}

        {isPending ? (
          <div className={styles.emptyWallet}>
            <IonSpinner />
            <p>{t("wallet.loadingNft")}</p>
          </div>
        ) : listNft.length > 0 ? (
          <>
            <div className={styles.viewContainer}>
              <IonGrid
                style={{
                  width: "100%",
                  marginleft: 0,
                  marginRight: 0,
                  marginTop: "15px",
                }}
              >
                <IonRow>
                  {listNft.map((item, index) => {
                    return (
                      <IonCol key={index} size="6">
                        <div
                          className={`${styles.nftBox}`}
                          style={{
                            backgroundImage: `url(${item.metadata.cover})`,
                          }}
                          onClick={() => {
                            setCurrentSelectedNftIndex(index);
                            toggleActionModal();
                          }}
                        >
                          <div className={`${styles.nftBoxLabel}`}>
                            <p>
                              <b>NFT #{index + 1}</b>
                            </p>
                          </div>
                        </div>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </IonGrid>
            </div>
          </>
        ) : (
          <>
            <div className={styles.emptyWallet}>
              <IonIcon icon={walletIcon} size="large"></IonIcon>
              <h1>{t("wallet.noNFT")}</h1>
            </div>
          </>
        )}

        {/* ACTION MODAL - NFT */}
        <IonModal id="custom-modal" isOpen={showModalAction} className="">
          <div className="wrapper">
            <UserNft
              selectedNftIndex={currentSelectedNftIndex}
              userNfts={listNft}
              toggleModal={() => toggleActionModal()}
            />
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
export default NFTsPage;
