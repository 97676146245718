import { IonContent, IonImg, IonPage } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';
import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
// import 'swiper/swiper.min.css';
import 'swiper/css';
import MyButtonWithBorder from '../../components/my-button-with-border/my-button-with-border';
import { LocalizationContext } from '../../contexts/localization.context';
import { UserContext } from '../../contexts/user.context';
import { Locale } from '../../models/contexts/localization.context.models';
import { UserStatus } from '../../models/contexts/user.context.model';
import { Routes } from '../../routes';
import styles from './language-selection-page.module.css';

const LanguageSelectionPage = () => {
    const history = useHistory()
    const localizationContext = useContext(LocalizationContext)
    const userContext = useContext(UserContext)
    const location = useLocation()

    useEffect(() => {
        if (location.pathname !== Routes.LANGUAGE_SELECTION) return;
        console.log("USER_STATUS", userContext?.user?.status)
        if (userContext?.user?.status === UserStatus.LOGGED) {
            history.push(Routes.USER_ITINERARIES)
        } else if (userContext?.user?.status === UserStatus.NOT_LOGGED) {
            if (localStorage.getItem('locale') !== null) {
                if (localStorage.getItem('locale') === 'it') {
                    localizationContext.setLocale(Locale.IT)
                } else {
                    localizationContext.setLocale(Locale.EN)
                }
            }
            if (localStorage.getItem('logged') === null) {
                history.push(Routes.ACCESS)
            }
        }
    }, [userContext])

    function setLocale(lang: string) {
        if (lang == 'it') {
            localStorage.setItem('locale', 'it')
            onClick(Locale.IT)
        } else {
            localStorage.setItem('locale', 'en')
            onClick(Locale.EN)
        }
    }

    async function onClick(lang: Locale) {
        localizationContext.setLocale(lang)
        if (userContext?.user?.status === UserStatus.LOGGED) {
            history.push(Routes.USER_ITINERARIES)
        } else {
            history.push(Routes.INTRO);
        }
    }

    return (
        <IonPage>
            <IonContent>
                <div className={styles.languageSelectionContainer}>
                    <IonImg src='assets/logo.svg' alt='Zonzo logo' className={styles.logo}></IonImg>
                    <div>
                        <MyButtonWithBorder onClick={() => setLocale('it')} size='small' className={styles.itButton}>IT</MyButtonWithBorder>
                        <span className={styles.languageButtonsDivider}>/</span>
                        <MyButtonWithBorder onClick={() => setLocale('en')} size='small' className={styles.enButton}>EN</MyButtonWithBorder>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};
export default LanguageSelectionPage;